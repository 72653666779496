import SkeletonSwiperComponent from "@/components/swiper-component/SkeletonSwiperComponent";
import useRecommendedMeasurements from "@/hooks/useRecommendedMeasurements";
import { t } from "i18next";
import { Col, Container, Row } from "react-bootstrap";
import MeasurementSwiper from "./MeasurementSwiper";

function RecommendedMeasurements() {

    const { recommendedMeasurements, isLoading } = useRecommendedMeasurements();

    return (
        (isLoading || recommendedMeasurements?.length > 0) &&
        <Container fluid className="pt-3 pb-4">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="pb-3 pt-3">
                            <h2>{t('measurements:overview:recommendedTitle')}</h2>
                        </div>
                        {recommendedMeasurements?.length > 0 &&
                            <MeasurementSwiper measurements={recommendedMeasurements} />
                        }
                        {isLoading &&
                            <SkeletonSwiperComponent />
                        }
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default RecommendedMeasurements