
import useStartedLearningCourses from '@/hooks/useStartedLearningCourses';
import useStartedLearningPaths from '@/hooks/useStartedLearningPaths';
import { t } from 'i18next';
import { Container } from 'react-bootstrap';
import LearningResourceSwiper from './LearningResourceSwiper';

function StartedLearningResources() {

    const { learningCourses, learningCoursesLoading } = useStartedLearningCourses();
    const { learningPaths, learningPathsLoading } = useStartedLearningPaths();

    return (
        (learningCourses?.length > 0 || learningPaths?.length > 0) &&
        <Container fluid className="pt-3 pb-4 position-relative">
            <Container className="position-relative">
                <div>
                    <h2 className="d-flex align-items-center pb-3">
                        <span>{t('learning:overview:startedTitle')}</span>
                        <i className='fas fa-arrow-right fa-sm ms-2' />
                    </h2>
                    <LearningResourceSwiper resources={[...learningCourses || [], ...learningPaths || []].sort((a, b) => new Date(a.started)?.getTime() - new Date(b.started)?.getTime())} />
                </div>
            </Container>
        </Container>
    )
}

export default StartedLearningResources