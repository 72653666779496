import { Modal } from "react-bootstrap";
import { AttributeFeedback } from "@/general/model/attribute-feedback";
import { AttributeCategory } from "@/general/model/attribute-category";
import { t } from "i18next";
import { Row, Col } from "react-bootstrap";
import FeedbackAttributes from "@/development/components/profile-evaluation/FeedbackAttributes"; // Import Bootstrap's Row and Col components
import Button from '@/components/button/Button';

type EvaluationPopupProps = {
    feedbacks: AttributeFeedback[];
    showModal: boolean;
    handleClose: () => void;
}

function EvaluationPopup({ feedbacks, showModal, handleClose }: EvaluationPopupProps) {
    // Filter the feedbacks by category
    const personalityFeedbacks = feedbacks.filter(fb => fb.attributeCategory === AttributeCategory.Personality);
    const skillsFeedbacks = feedbacks.filter(fb => fb.attributeCategory === AttributeCategory.Skills);
    const potentialFeedbacks = feedbacks.filter(fb => fb.attributeCategory === AttributeCategory.Potential);

    return (
        <Modal show={showModal} onHide={handleClose} centered size={"xl"} className="evaluation-popup wihv-modal">
            <Modal.Header>
                <Modal.Title>Feedback</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Use Row and Col to match the structure */}
                <Row className="categories">
                    {/* Personality Category */}
                    <Col sm={{ span: 12, offset: 0 }} className="mt-1">
                        <FeedbackAttributes category={AttributeCategory.Personality} attributes={personalityFeedbacks} />
                    </Col>
                    
                    {/* Skills Category */}
                    <Col sm={{ span: 12, offset: 0 }} className="mt-5">
                        <FeedbackAttributes category={AttributeCategory.Skills} attributes={skillsFeedbacks} />
                    </Col>
                    
                    {/* Potential Category */}
                    <Col sm={{ span: 12, offset: 0 }} className="mt-5">
                        <FeedbackAttributes category={AttributeCategory.Potential} attributes={potentialFeedbacks} />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button text={t('development:evaluation:modalCloseBtn')} type='button' displayType='secondary' onClick={() => handleClose()} />
            </Modal.Footer>
        </Modal>
    );
}

export default EvaluationPopup;
