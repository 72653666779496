import { LearningResourceType } from '@/learning/models/learning-resource-type';
import { SupplierType } from '@/learning/models/supplier-type';
import { FileUploadType } from '@/profile/model/file-upload';
import { Gender } from '@/profile/model/gender';
import { LanguageLevel } from '@/profile/model/language-level';
import { useTranslation } from 'react-i18next';
import { PersonalitySubCategory, PotentialSubCategory, SkillsSubCategory } from '../model/attribute';
import { AttributeCategory } from '../model/attribute-category';
import { OriginType } from '../model/data-origin';
import { FeedbackStatus } from '../model/feedback-status';

export function useAttributeCategoryTranslation() {

    const { t } = useTranslation();

    function translateAttributeCategory(attributeCategory: AttributeCategory): string {

        switch (attributeCategory) {
            case AttributeCategory.Personality:
                return t('enums:attributeCategories:personality');
            case AttributeCategory.Potential:
                return t('enums:attributeCategories:potential');
            case AttributeCategory.Skills:
                return t('enums:attributeCategories:skills');
            case AttributeCategory.LabourMarket:
                return t('enums:attributeCategories:labourMarket');
            default:
                return '';
        }
    }

    return { translateAttributeCategory };
}

export function useAttributeSubcategoryTranslation() {

    const { t } = useTranslation();

    function translateAttributeSubcategory(attributeCategory: AttributeCategory, attributeSubcategory: PersonalitySubCategory | SkillsSubCategory | PotentialSubCategory): string {

        if (attributeCategory === AttributeCategory.Personality) {
            switch (attributeSubcategory) {
                case PersonalitySubCategory.Identity:
                    return t('enums:attributeSubcategories:identity');
                case PersonalitySubCategory.Behavior:
                    return t('enums:attributeSubcategories:behavior');
                case PersonalitySubCategory.Values:
                    return t('enums:attributeSubcategories:values');
                case PersonalitySubCategory.Interests:
                    return t('enums:attributeSubcategories:interests');
                case PersonalitySubCategory.Goals:
                    return t('enums:attributeSubcategories:goals');
                default:
                    return '';
            }
        }

        if (attributeCategory === AttributeCategory.Skills) {
            switch (attributeSubcategory) {
                case SkillsSubCategory.Knowledge:
                    return t('enums:attributeSubcategories:knowledge');
                case SkillsSubCategory.Skills:
                    return t('enums:attributeSubcategories:skills');
                default:
                    return '';
            }
        }

        if (attributeCategory === AttributeCategory.Potential) {
            switch (attributeSubcategory) {
                case PotentialSubCategory.Growth:
                    return t('enums:attributeSubcategories:growth');
                case PotentialSubCategory.Potential:
                    return t('enums:attributeSubcategories:potential');
                case PotentialSubCategory.Incentives:
                    return t('enums:attributeSubcategories:incentives');
                default:
                    return '';
            }
        }

        return '';
    }

    return { translateAttributeSubcategory };
}

export function useOriginTypeTranslation() {

    const { t } = useTranslation();

    function translateOriginType(originType: OriginType): string {

        switch (originType) {
            case OriginType.InputFields:
                return t('enums:originTypes:inputFields');
            case OriginType.Measurement:
                return t('enums:originTypes:measurement');
            case OriginType.Reflection:
                return t('enums:originTypes:reflection');
            case OriginType.Feedback:
                return t('enums:originTypes:feedback');
            case OriginType.Uploads:
                return t('enums:originTypes:uploads');
            case OriginType.Favorites:
                return t('enums:originTypes:favorites');
            case OriginType.Unknown:
            default:
                return '';
        }
    }

    return { translateOriginType };
}

export function useLanguageLevelTranslation() {

    const { t } = useTranslation();

    function translateLanguageLevel(languageLevel: LanguageLevel): string {

        switch (languageLevel) {
            case LanguageLevel.A1:
                return t('enums:languageLevel:a1');
            case LanguageLevel.A2:
                return t('enums:languageLevel:a2');
            case LanguageLevel.B1:
                return t('enums:languageLevel:b1');
            case LanguageLevel.B2:
                return t('enums:languageLevel:b2');
            case LanguageLevel.C1:
                return t('enums:languageLevel:c1');
            case LanguageLevel.C2:
                return t('enums:languageLevel:c2');
            default:
                return t('enums:languageLevel:unknown');
        }
    }

    return { translateLanguageLevel };
}

export function useFeedbackStatusTranslation() {

    const { t } = useTranslation();

    function translateFeedbackStatus(feedbackStatus: FeedbackStatus): string {

        switch (feedbackStatus) {
            case FeedbackStatus.Pending:
                return t('enums:feedbackStatus:pending');
            case FeedbackStatus.Completed:
                return t('enums:feedbackStatus:completed');
            default:
                return t('enums:feedbackStatus:unknown');
        }
    }

    return { translateFeedbackStatus };
}

export function useFileUploadTypeTranslation() {

    const { t } = useTranslation();

    function translateFileUploadType(fileUploadType: FileUploadType): string {

        switch (fileUploadType) {
            case FileUploadType.CV:
                return t('enums:fileUploadType:cv');
            case FileUploadType.Certificate:
                return t('enums:fileUploadType:certificate');
            case FileUploadType.MotivationLetter:
                return t('enums:fileUploadType:motivationLetter');
            case FileUploadType.Other:
            default:
                return t('enums:fileUploadType:other');
        }
    }

    return { translateFileUploadType };
}

export function useSupplierTypeTranslation() {

    const { t } = useTranslation();

    function translateSupplierType(supplierType: SupplierType): string {

        switch (supplierType) {
            case SupplierType.LinkedInLearning:
                return t('enums:supplierType:linkedInLearning');
            case SupplierType.Unknown:
            default:
                return t('enums:supplierType:unknown');
        }
    }

    return { translateSupplierType };
}

export function useLearningResourceTypeTranslation() {

    const { t } = useTranslation();

    function translateLearningResourceType(resourceType: LearningResourceType): string {

        switch (resourceType) {
            case LearningResourceType.LearningPath:
                return t('enums:learningResourceType:learningPath');
            case LearningResourceType.LearningCourse:
            default:
                return t('enums:learningResourceType:learningCourse');
        }
    }

    return { translateLearningResourceType };
}

export function useGenderTranslation() {

    const { t } = useTranslation();

    function translateGender(gender: Gender): string {

        switch (gender) {
            case Gender.Male:
                return t('enums:gender:male');
            case Gender.Female:
                return t('enums:gender:female');
            case Gender.Other:
            default:
                return t('enums:gender:other');
        }
    }

    return { translateGender };
}