import { useAttributeCategoryTranslation, useAttributeSubcategoryTranslation } from "@/general/i18n/translation-helpers";
import { Attribute, PersonalitySubCategory, SkillsSubCategory, PotentialSubCategory } from "@/general/model/attribute";
import { AttributeCategory } from "@/general/model/attribute-category";
import { useState } from "react";
import AttributeDetailsModal from "./AttributeDetailsModal";
import './CategoryAttributes.scss';

type CategoryAttributesProps = {
    category: AttributeCategory;
    attributes: Attribute[];
};

function CategoryAttributes({ category, attributes }: CategoryAttributesProps) {
    const { translateAttributeCategory } = useAttributeCategoryTranslation();
    const { translateAttributeSubcategory } = useAttributeSubcategoryTranslation();
    const [showAttributeModal, setShowAttributeModal] = useState(false);
    const [selectedAttribute, setSelectedAttribute] = useState<Attribute>(null);
    
    const groupedAttributes = attributes.reduce((acc, attribute) => {
        const subCategory = attribute.subCategory;
        if (!acc[subCategory]) {
            acc[subCategory] = [];
        }
        acc[subCategory].push(attribute);
        return acc;
    }, {} as Record<number, Attribute[]>);

    // Define all possible subcategories based on the category
    const getSubcategories = () => {
        switch (category) {
            case AttributeCategory.Personality:
                return Object.values(PersonalitySubCategory).filter(value => typeof value === 'number');
            case AttributeCategory.Skills:
                return Object.values(SkillsSubCategory).filter(value => typeof value === 'number');
            case AttributeCategory.Potential:
                return Object.values(PotentialSubCategory).filter(value => typeof value === 'number');
            default:
                return [];
        }
    };

    const subcategories = getSubcategories();
    
    return (
        <div className={`category-attributes category-${category}`}>
            <header>
                <h2 className="title">{translateAttributeCategory(category)}</h2>
                <div className="count">{attributes.length}</div>
            </header>

            {subcategories.map((subCategory) => {
                const subCategoryAttributes = groupedAttributes[subCategory] || [];
                return (
                    <div key={subCategory} className="subcategory">
                        <h3 className="subtitle">{translateAttributeSubcategory(category, subCategory)}</h3>

                        {subCategoryAttributes.length > 0 ? (
                            <div className="attributes">
                                {subCategoryAttributes.map((attribute, index) => (
                                    <span
                                        key={index}
                                        className={`attribute ${
                                            attribute.userFeedback === "-1"
                                                ? 'negative-feedback'
                                                : attribute.userFeedback === "0"
                                                    ? 'neutral-feedback'
                                                    : attribute.userFeedback === "1"
                                                        ? 'very-good-feedback'
                                                        : ''
                                        }`}
                                        tabIndex={0}
                                        onClick={() => {
                                            setSelectedAttribute(attribute);
                                            setShowAttributeModal(true);
                                        }}
                                    >
                                        {attribute.name}
                                        {attribute.userFeedback === "-1" && (
                                            <i className="icon far fa-star" title="Bad feedback (Empty star)" />
                                        )}
                                        {attribute.userFeedback === "0" && (
                                            <i className="icon fas fa-star-half-alt" title="Neutral feedback (Half star)" />
                                        )}
                                        {attribute.userFeedback === "1" && (
                                            <i className="icon fas fa-star" title="Very Good feedback (Filled star)" />
                                        )}
                                    </span>
                                ))}
                            </div>
                        ) : (
                            <div className="empty-subcategory-message">
                                <p>Je hebt nog geen kenmerken in deze categorie.</p>
                            </div>
                        )}
                    </div>
                );
            })}

            <AttributeDetailsModal
                showAttributeModal={showAttributeModal}
                setShowAttributeModal={setShowAttributeModal}
                attribute={selectedAttribute}
            />
        </div>
    );
}

export default CategoryAttributes;