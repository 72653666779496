import { Form, InputGroup } from 'react-bootstrap';
import './SearchComponent.scss';

interface SearchComponentProps {
    placeholder: string;
    onSearchChange: (value: string) => void;
}

function SearchComponent({ placeholder, onSearchChange }: SearchComponentProps) {
    return (
        <InputGroup className='search'>
            <InputGroup.Text className='icon-left'>
                <i className="fas fa-search"></i>
            </InputGroup.Text>
            <Form.Control type="text" placeholder={placeholder} defaultValue={''} onChange={(e) => onSearchChange(e.target.value)} />
        </InputGroup>
    );
}

export default SearchComponent;