import Button from '@/components/button/Button';
import { ConfigContext } from '@/context/ConfigContext';
import { USER_FEEDBACK_AGREE, USER_FEEDBACK_DISAGREE, USER_FEEDBACK_NEUTRAL } from '@/general/constants';
import { Attribute } from '@/general/model/attribute';
import { DataOrigin, OriginType } from '@/general/model/data-origin';
import useAuthenticatedRequest from '@/hooks/useAuthenticatedRequest';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import './AttributeDetailsModal.scss';

type AttributeDetailsModalProps = {
    showAttributeModal: boolean;
    setShowAttributeModal: (show: boolean) => void;
    attribute: Attribute;
}

function AttributeDetailsModal({ showAttributeModal, setShowAttributeModal, attribute }: AttributeDetailsModalProps) {

    const authenticatedRequest = useAuthenticatedRequest();
    const configContext = useContext(ConfigContext);
    const [showFeedback, setShowFeedback] = useState(attribute?.userFeedback == null);

    useEffect(() => {
        setShowFeedback(attribute?.userFeedback == null);        
    }, [attribute]);

    function getLinkByOriginType(origin: DataOrigin): string {
        switch (origin.type) {
            case OriginType.Measurement:
                return `/measurements/${origin.value}/results`;
            case OriginType.InputFields:
            case OriginType.Uploads:
                return '/profile';
            case OriginType.Feedback:
                return '/development';
            default:
                return '/';
        }
    }

    function handleFeedback(attribute: Attribute, feedback: string) {
        authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.attributes.feedback.replace('{id}', attribute?.id), 'POST', { feedback: feedback })
            .then(() => {
                toast.success(t('development:toasts:reflectionSaveSuccess'));
                attribute.userFeedback = feedback;
            }).catch((error) => {
                toast.error(t('development:toasts:reflectionSaveFailed'));
                console.error(error);
            });
    }

    return (
        <Modal className='wihv-modal wihv-modal--info attributes-detail-modal' centered show={showAttributeModal} onHide={() => setShowAttributeModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{attribute?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {attribute?.description &&
                    <div className='description'>
                        <h3 className='title'>{t('development:attributeDetails:descriptionTitle')}</h3>
                        <p className='text'>{attribute?.description}</p>
                    </div>
                }

                {attribute?.origins && attribute?.origins.length > 0 &&
                    <div className='origins'>
                        <h3 className='title'>{t('development:attributeDetails:sourcesTitle')}</h3>
                        {attribute?.origins?.map((origin, i) => (
                            <div className={`origin`} key={i}>
                                <div className="icon">
                                    {origin.type === OriginType.Measurement && <i className='fas fa-clipboard-question fa-lg'></i>}
                                    {origin.type === OriginType.InputFields && <i className='fas fa-user-edit fa-lg'></i>}
                                    {origin.type === OriginType.Favorites && <i className='fas fa-heart fa-lg'></i>}
                                    {origin.type === OriginType.Uploads && <i className='fas fa-upload fa-lg'></i>}
                                    {origin.type === OriginType.Feedback && <i className='fas fa-comments fa-lg'></i>}
                                </div>
                                <Link to={getLinkByOriginType(origin)} className="link">
                                    <span className={`name`}>{origin.displayValue}</span>
                                </Link>
                            </div>
                        ))}
                    </div>
                }

                {showFeedback &&
                    <div className='feedback'>
                        <p className='description'>{t('development:attributeDetails:feedbackDescription')}</p>
                        <div className='options'>
                            <button
                                className={`option disagree ${attribute?.userFeedback === USER_FEEDBACK_DISAGREE ? 'selected' : ''}`}
                                onClick={() => handleFeedback(attribute, "-1")}>
                                <i className='icon far fa-star' title="Bad feedback"></i>
                                <span className='text'>{t('development:attributeDetails:feedbackBtnDisagree')}</span>
                            </button>
                            <button
                                className={`option neutral ${attribute?.userFeedback === USER_FEEDBACK_NEUTRAL ? 'selected' : ''}`}
                                onClick={() => handleFeedback(attribute, "0")}>
                                <i className='icon fas fa-star-half-alt' title="Neutral feedback"></i>
                                <span className='text'>{t('development:attributeDetails:feedbackBtnNeutral')}</span>
                            </button>
                            <button
                                className={`option agree ${attribute?.userFeedback === USER_FEEDBACK_AGREE ? 'selected' : ''}`}
                                onClick={() => handleFeedback(attribute, "1")}>
                                <i className='icon fas fa-star' title="Very Good feedback"></i>
                                <span className='text'>{t('development:attributeDetails:feedbackBtnAgree')}</span>
                            </button>
                        </div>
                    </div>
                }

                {!showFeedback &&
                    <div className='d-flex justify-content-end pt-4'>
                        <Button displayType='tertiary' icon='pen-to-square'
                                text={t('development:attributeDetails:changeFeedbackBtn')}
                                onClick={() => setShowFeedback(true)}/>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default AttributeDetailsModal