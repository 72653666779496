import { DEFAULT_LANGUAGE } from '@/general/constants';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

require('dayjs/locale/nl');
require('dayjs/locale/en');

const useInitialLanguage = () => {
    const { i18n } = useTranslation();
    const defaultLanguage = DEFAULT_LANGUAGE;

    useEffect(() => {
        const setInitialLanguage = () => {

            const savedLanguage = localStorage.getItem('selectedLanguage');

            if (!savedLanguage) {
                localStorage.setItem('selectedLanguage', defaultLanguage);
            }

            i18n.changeLanguage(savedLanguage ?? defaultLanguage);
            dayjs.locale(savedLanguage ?? defaultLanguage);
        };

        setInitialLanguage();
    }, [i18n]);

};

export default useInitialLanguage;