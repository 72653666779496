import Onboarding from '@/components/onboarding/Onboarding';
import { BreadcrumbsContext } from '@/context/BreadcrumbsContext';
import { ConfigContext } from '@/context/ConfigContext';
import ProfileProvider from '@/context/ProfileProvider';
import { AttributeCategory } from '@/general/model/attribute-category';
import useCurrentAccount from '@/hooks/useCurrentAccount';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { t } from 'i18next';
import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import useSWR from 'swr';
import './DashboardOverview.scss';
import CreateProfileTile from './components/CreateProfileTile';
import CurrentlyLearningTile from './components/CurrentlyLearningTile';
import DiscoverPlatformTile from './components/DiscoverPlatformTile';
import ProfileRequirementCategoryCompletionTile from './components/ProfileRequirementCategoryCompletionTile';
import ProfileRequirementCompletionTile from './components/ProfileRequirementCompletionTile';

function DashboardOverview() {

    useDocumentTitle(t('documentTitles:dashboard'));
    const configContext = useContext(ConfigContext);
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const isAuthenticated = useIsAuthenticated();
    const { currentAccount } = useCurrentAccount();
    const [showOnboarding, setShowOnboarding] = useState(false);
    const { data: profile, isLoading, error, mutate } = useSWR(isAuthenticated ? configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.profile.detail : null, { shouldRetryOnError: false });

    // useEffect(() => {
    //     if (isAuthenticated && !profile?.onboardingCompleted) {
    //         setShowOnboarding(true);
    //     }
    // }, [isAuthenticated, profile]);

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:dashboard')
        }]);
    }, [setBreadcrumbs]);

    return (
        <>
            <Container fluid className="dashboard">
                <Container>

                    {showOnboarding && isAuthenticated &&
                        <ProfileProvider>
                            <Onboarding onConfirm={() => { setShowOnboarding(false); }} />
                        </ProfileProvider>
                    }

                    {/* <Row className='pt-3 pt-md-5 pb-3'>
                        <Col sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <h1 className='welcome'>{t('dashboard:title', { name: currentAccount?.name })}</h1>
                        </Col>
                    </Row> */}

                    <Row className='pt-5 pb-4'>
                        <Col sm={12} md={12} lg={12} xl={12} xxl={12} className='grid'>
                            <AuthenticatedTemplate>
                                <ProfileRequirementCompletionTile tileClassName='profile-completion' />
                                <CurrentlyLearningTile tileClassName='currently-learning' />
                                <ProfileRequirementCategoryCompletionTile attributeCategory={AttributeCategory.Personality} tileClassName='personality-completion' />
                                <ProfileRequirementCategoryCompletionTile attributeCategory={AttributeCategory.Skills} tileClassName='skills-completion' />
                                <ProfileRequirementCategoryCompletionTile attributeCategory={AttributeCategory.Potential} tileClassName='potential-completion' />
                            </AuthenticatedTemplate>

                            <UnauthenticatedTemplate>
                                <CreateProfileTile className='create-profile' />
                                <DiscoverPlatformTile className='discover-platform-professions' />
                            </UnauthenticatedTemplate>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default DashboardOverview