import useFavoriteProfessions from '@/hooks/useFavoriteProfessions';
import { t } from 'i18next';
import { Container } from 'react-bootstrap';
import ProfessionSwiper from './ProfessionSwiper';

function FavoriteProfessions() {

    const { favoriteProfessions } = useFavoriteProfessions();

    return (
        favoriteProfessions?.length > 0 &&
        <Container fluid className="pt-3 pb-4 position-relative">
            <Container className="position-relative">
                <div>
                    <h2 className="d-flex align-items-center pb-3">
                        <span>{t('professions:overview:favoritesTitle')}</span>
                        <i className='fas fa-star fa-xs ms-2' />
                    </h2>
                    <ProfessionSwiper professions={favoriteProfessions} />
                </div>
            </Container>
        </Container>
    )
}

export default FavoriteProfessions