import { useEffect, useState } from 'react';
import { InputGroup, Dropdown, Form } from 'react-bootstrap';
import './FilterComponent.scss';
import { t } from 'i18next';

export interface FilterOption {
    value: any;
    label: string;
    id: string;
    type: 'checkbox' | 'range';
    category: string;
    min?: number;
    max?: number;
}

interface FilterComponentProps {
    options: FilterOption[];
    onFiltersChange: (value: FilterOption[]) => void;
    placeholder?: string;
}

function FilterComponent({ options, onFiltersChange, placeholder = t('general:filters') }: FilterComponentProps) {

    const [selectedFilters, setSelectedFilters] = useState<FilterOption[]>([]);
    const [openCategories, setOpenCategories] = useState({});

    useEffect(() => {
        if (options.length <= 3) {
            options.forEach((option) => setOpenCategories(prevState => ({ ...prevState, [option.category]: true })));
        } else {
            setOpenCategories(prevState => ({ ...prevState, [options[0].category]: true }));
        }
    }, [options]);

    const toggleCategory = (category) => {
        setOpenCategories(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    const categories = options.reduce((categories, option) => {
        (categories[option.category] = categories[option.category] || []).push(option);
        return categories;
    }, {});

    const updateFilters = (option: FilterOption, value: any) => {

        const index = selectedFilters.findIndex((filter) => filter.id === option.id);

        if (index >= 0) {
            if (selectedFilters[index]?.type === 'checkbox') {
                selectedFilters.splice(index, 1);
            }

            if (selectedFilters[index]?.type === 'range') {
                selectedFilters[index] = { ...option, value };
            }
        } else {
            selectedFilters.push({ ...option, value });
        }

        setSelectedFilters([...selectedFilters]);
        onFiltersChange(selectedFilters);
    }

    return (
        <InputGroup className='filter'>
            <InputGroup.Text className='icon-left'>
                <i className="fas fa-filter"></i>
            </InputGroup.Text>

            <Dropdown drop='down-centered'>

                <Dropdown.Toggle className="btn btn--wit">
                    {placeholder}
                    {selectedFilters?.length > 0 && <span className='filter__count'>{selectedFilters?.length}</span>}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {Object.entries(categories).map(([category, options]) => (

                        <div className='filter__category-category' key={category}>

                            <Dropdown.ItemText className='filter__category' role='button' onClick={() => toggleCategory(category)}>
                                <span>{category}</span>
                                <i className={`fas ms-2 fa-chevron-${openCategories[category] ? 'up' : 'down'}`}></i>
                            </Dropdown.ItemText>

                            {openCategories[category] && (options as FilterOption[])?.map((option) => (
                                option.type === 'checkbox'
                                    ?
                                    (
                                        <Dropdown.ItemText className='filter__checkbox' key={option.id}>
                                            <input type='checkbox' id={option.id} value={option.value} checked={selectedFilters.some((x) => x.id === option.id)} onChange={(e) => updateFilters(option, e.target.value)} />
                                            <label htmlFor={option.id}>{option.label}</label>
                                        </Dropdown.ItemText>
                                    )
                                    :
                                    (
                                        <Dropdown.ItemText key={option.id}>
                                            <div className='d-flex justify-content-between mb-2'>
                                                <span>{option.label}</span>
                                                {
                                                    selectedFilters.find((x) => x.id === option.id)?.value &&
                                                    <span className='filter__range-value'>{selectedFilters.find((x) => x.id === option.id)?.value}</span>
                                                }
                                            </div>
                                            <Form.Range defaultValue={option.max} min={option.min} max={option.max} onChange={(e) => updateFilters(option, e.target.value)} />
                                            <div className='filter__range-values'>
                                                <span>{option.min}</span>
                                                <span>{option.max}</span>
                                            </div>
                                        </Dropdown.ItemText>
                                    )
                            ))}
                        </div>
                    ))}

                    <Dropdown.Item className='filter__clear' onClick={() => { setSelectedFilters([]); onFiltersChange([]); }}>
                        {t('general:clearFilters')}
                        <i className="fas fa-trash-alt ms-2"></i>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </InputGroup>
    );
}

export default FilterComponent;