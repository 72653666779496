import FilterComponent from "@/components/filter-component/FilterComponent";
import SearchComponent from "@/components/search/SearchComponent";
import { BreadcrumbsContext } from "@/context/BreadcrumbsContext";
import { AttributeCategory } from "@/general/model/attribute-category";
import useCurrentAccount from "@/hooks/useCurrentAccount";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import useProfileAttributes from "@/hooks/useProfileAttributes";
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import './DevelopmentOverview.scss';
import CategoryAttributes from "./components/CategoryAttributes";
import ProfileEvaluation from "./components/profile-evaluation/ProfileEvaluation";

function DevelopmentOverview() {

    useDocumentTitle(t('documentTitles:development'));
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const { currentAccount } = useCurrentAccount();
    const { filteredAttributes, filterOptions, isLoading, updateFilters, updateSearchterm } = useProfileAttributes();

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:development')
        }]);
    }, [setBreadcrumbs]);

    return (
        <div className="development-overview">

            {/* TODO: Loading & translations */}

            <Container fluid className='introduction py-5'>
                <Container>
                    <h1 className="title">De kenmerken van <span className="name">{currentAccount?.name}</span></h1>
                    <p className="description">
                        Dit is een overzicht van jouw persoonlijke ontwikkeling. Hier vind je een overzicht van jouw persoonlijke eigenschappen, vaardigheden en potentieel. Dit overzicht is gebaseerd op de gegevens die je hebt ingevuld in je profiel.
                        Wanneer je op een kenmerk klikt krijg je meer informatie over dit kenmerk, zoals de omschrijving en de bronnen waarop dit kenmerk is gebaseerd. Klopt er een kenmerk niet? Geef feedback via de knop onderaan de pagina.
                    </p>
                </Container>
            </Container>

            <Container fluid>
                <Container>
                    {filteredAttributes &&
                        <>
                            <Row className="my-4">
                                <Col sm={12}>
                                    <div className='filters d-flex gap-3 flex-column flex-md-row'>
                                        <FilterComponent options={filterOptions} onFiltersChange={(value) => updateFilters(value)} placeholder="Filter op bronnen" />
                                        <SearchComponent placeholder={'Zoek op kenmerk'} onSearchChange={(value) => updateSearchterm(value)} />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="categories">
                                <Col sm={{ span: 12, offset: 0 }}>
                                    <CategoryAttributes category={AttributeCategory.Personality} attributes={filteredAttributes.filter(x => x.category === AttributeCategory.Personality)} />
                                </Col>
                                <Col sm={{ span: 12, offset: 0 }} className="mt-5">
                                    <CategoryAttributes category={AttributeCategory.Skills} attributes={filteredAttributes.filter(x => x.category === AttributeCategory.Skills)} />
                                </Col>
                                <Col sm={{ span: 12, offset: 0 }} className="mt-5">
                                    <CategoryAttributes category={AttributeCategory.Potential} attributes={filteredAttributes.filter(x => x.category === AttributeCategory.Potential)} />
                                </Col>
                            </Row>
                        </>
                    }
                </Container>
            </Container>

            <Container fluid className='feedback py-5 mt-5'>
                <Container>
                    <ProfileEvaluation />
                </Container>
            </Container>
        </div>
    )
}

export default DevelopmentOverview;
