// Definitions of available user permissions
export const PERMISSIONS = {
    MEASUREMENTS_ALL: 'Measurements.All',
    LEARNING_ALL: 'Learning.All',
    PROFESSIONS_ALL: 'Professions.All',
    PROFESSIONS_RECOMMENDED: 'Professions.Recommended',
    QUICKSCAN: 'Quickscan',
}

// Defenitions of user feedback on attributes
export const USER_FEEDBACK_DISAGREE = "-1"
export const USER_FEEDBACK_NEUTRAL = "0"
export const USER_FEEDBACK_AGREE = "1"

// Definitions for file uploads
export const MAX_FILE_SIZE = 10485760 // 10MB
export const MAX_FILES = 1
export const MAX_PROFILE_FILES_PER_UPLOAD = 3

// Definitions for date formats
export const PROFILE_DATE_FORMAT = 'MMMM YYYY'
export const DATE_FORMAT_FULL = 'DD-MM-YYYY'

// Definitions for profile properties
export const PROFILE_LANGUAGE_OPTIONS = ["nl", "en", "de", "fr", "es", "ua"];
export const PROFILE_PERSONAL_DATA_FIELDS = ['givenName', 'surname', 'email', 'gender', 'dateOfBirth', 'phone', 'street', 'houseNumber', 'postalCode', 'city'];

// Definitions for external links
export const PRIVACY_POLICY_LINK = 'https://werkinhetvooruitzicht.nl/privacy-policy'
export const COOKIE_POLICY_LINK = 'https://werkinhetvooruitzicht.nl/cookie-policy'
export const TERMS_AND_CONDITIONS_LINK = 'https://werkinhetvooruitzicht.nl/terms-and-conditions'
export const YOUTUBE_NOCOOKIE_URL = 'https://www.youtube-nocookie.com'

// Definitions for user account settings
export const AD_TOKEN_CLAIMS_IDENTITY_PROVIDER = 'idp'
export const IDENTITY_PROVIDER_LINKEDIN_NAME = 'linkedin.com'
export const IDENTITY_PROVIDER_LINKEDIN_PASSWORD_RESET_URL = 'https://www.linkedin.com/uas/request-password-reset'

// Definitions for platform standard values
export const DEFAULT_LANGUAGE = 'nl'
export const DEFAULT_TOAST_DURATION = 10000 // 10 seconds