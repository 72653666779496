import CTAFullWidth from "@/components/cta/CTAFullWidth";
import ScrollToTopButton from "@/components/scroll-to-top/ScrollToTopButton";
import { BreadcrumbsContext } from "@/context/BreadcrumbsContext";
import { PERMISSIONS } from "@/general/constants";
import useCurrentAccount from "@/hooks/useCurrentAccount";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import useRecommendedProfessions from "@/hooks/useRecommendedProfessions";
import { t } from "i18next";
import { useContext, useEffect } from "react";
import { Container } from "react-bootstrap";
import './ProfessionsOverview.scss';
import FavoriteProfessions from "./components/FavoriteProfessions";
import ProfessionCategories from "./components/ProfessionCategories";
import ProfessionList from "./components/ProfessionList";
import RecommendedProfessions from "./components/RecommendedProfessions";

function ProfessionsOverview() {

    useDocumentTitle(t('documentTitles:professions'));
    const { setBreadcrumbs } = useContext(BreadcrumbsContext);
    const { hasPermission } = useCurrentAccount();
    const { mutate } = useRecommendedProfessions();

    useEffect(() => {
        mutate();
    }, []);

    useEffect(() => {
        setBreadcrumbs([{
            label: t('documentTitles:professions')
        }]);
    }, [setBreadcrumbs]);

    return (

        <div className="profession-overview">

            <RecommendedProfessions />

            <FavoriteProfessions />

            <Container fluid className="pt-3 pb-5">
                <Container>
                    <ProfessionCategories />
                </Container>
            </Container>

            <Container fluid className="gradient-background py-5">
                <Container>
                    {!hasPermission(PERMISSIONS.PROFESSIONS_ALL)
                        ?
                        <CTAFullWidth
                            title={t('CTA:upgradeDefault:title')}
                            description={t('CTA:upgradeDefault:description')}
                            imageSrc={'/assets/img/WIHV_3D_Visual_Upgrade_Arrow_Icon.jpg'}
                            buttonText={t('CTA:upgradeDefault:btn')}
                            buttonLink={'/vouchers'} />
                        :
                        <CTAFullWidth
                            title={t('CTA:default:title')}
                            description={t('CTA:default:description')}
                            imageSrc={'/assets/img/professions/WIHV_Professions_Helpdesk.jpg'}
                            buttonText={t('CTA:default:btn')}
                            buttonLink={'/profile'} />
                    }
                </Container>
            </Container>

            <Container fluid className="py-5">
                <Container>
                    <ProfessionList />
                </Container>
            </Container>

            <ScrollToTopButton section='list' />
        </div>
    )
}

export default ProfessionsOverview