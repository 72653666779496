import MeasurementCard from '@/components/cards/MeasurementCard';
import FilterComponent, { FilterOption } from '@/components/filter-component/FilterComponent';
import SearchComponent from '@/components/search/SearchComponent';
import SortComponent from '@/components/sort-component/SortComponent';
import useAllMeasurements from '@/hooks/useAllMeasurements';
import { t } from 'i18next';
import { Col, Row } from 'react-bootstrap';
import './MeasurementList.scss';

function MeasurementList() {

    const { filteredMeasurements, isLoading, updateSearchterm, updateSorting, updateFilters } = useAllMeasurements();

    const sortingOptions = [
        { label: t('measurements:overview:listSortTitleAsc'), value: 'title asc' },
        { label: t('measurements:overview:listSortDurationInMinutesAsc'), value: 'duration asc' }
    ];

    const filterOptions = [
        { value: '', label: t('measurements:overview:listFilterNotCompleted'), type: 'checkbox', category: t('measurements:overview:listFilterStatusCategory'), id: 'notCompleted' },
        { value: '', label: t('measurements:overview:listFilterCompleted'), type: 'checkbox', category: t('measurements:overview:listFilterStatusCategory'), id: 'completed' },
        { value: 30, label: t('measurements:overview:listFilterMaxDuration'), type: 'range', min: 1, max: 30, category: t('measurements:overview:listFilterDurationCategory'), id: 'maxDurationInMinutes' },
    ] as FilterOption[];

    return (
        <>
            <div className="measurement-list" id='list'>
                <Row>
                    <Col sm={12} className='header d-flex flex-column flex-lg-row justify-content-between align-items-center mb-5'>
                        <span className='d-flex flex-column pb-3 pb-lg-0'>
                            <h2 className='title'>{t('measurements:overview:listTitle')}</h2>
                            <span className='result-count'>{filteredMeasurements.length} {t('measurements:overview:listResultCount', { count: filteredMeasurements.length })}</span>
                        </span>

                        <div className='filters d-flex gap-3 flex-column flex-md-row'>
                            <FilterComponent options={filterOptions} onFiltersChange={(value) => updateFilters(value)} />
                            <SortComponent options={sortingOptions} onSortChange={(value) => updateSorting(value)} />
                            <SearchComponent placeholder={t('measurements:overview:listSearchPlaceholder')} onSearchChange={(value) => updateSearchterm(value)} />
                        </div>
                    </Col>
                </Row>

                {filteredMeasurements &&
                    <Row className='list gy-5'>
                        {filteredMeasurements.length > 0 &&
                            filteredMeasurements.map((measurement) => (
                                <Col sm={12} md={6} lg={4} key={measurement.id}>
                                    <MeasurementCard measurement={measurement} />
                                </Col>
                            ))
                        }
                    </Row>
                }

                {isLoading &&
                    <Row>
                        <Col sm={12} className='py-4 text-center'>
                            <i className="fas fa-circle-notch fa-spin fa-3x"></i>
                        </Col>
                    </Row>
                }

                {filteredMeasurements.length == 0 && !isLoading &&
                    <Col sm={12} className='no-results'>
                        <p>{t('measurements:overview:listSearchNoResults')}</p>
                    </Col>
                }
            </div>
        </>
    )
}

export default MeasurementList