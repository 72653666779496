import LinkButton from "@/components/button/LinkButton";
import DashboardTile from "./DashboardTile";
import './DiscoverPlatformTile.scss';

function DiscoverPlatformTile({ className = '' }) {

    //TODO: Add translations or remove?

    return (
        <DashboardTile tileClassName={className}>
            <div className="discover-platform-tile-content">
                <h2 className="title">Welk beroep past bij jou?</h2>
                <p className="description">
                    Ontdek welke beroepen bij jouw persoonlijkheid, vaardigheden en interesses passen.
                    Juist iets met cijfers of liever met mensen?
                    Wil je weten welke beroepen er zijn? Bekijk ze hier.
                </p>
                <LinkButton text='Bekijk alle beroepen' to={'/professions'} displayType="secondary" icon="arrow-right" />
            </div>
        </DashboardTile>
    )
}

export default DiscoverPlatformTile