import LinkButton from '@/components/button/LinkButton';
import PercentageRadialChart from '@/components/percentage-radial-chart/PercentageRadialChart';
import { removeHtmlTagsFromString } from '@/general/utilities/RemoveHtmlTagsFromString';
import { useScreenDetector } from '@/hooks/useScreenDetector';
import { RecommendedProfession } from '@/professions/model/recommended-profession';
import { t } from 'i18next';
import { useState } from 'react';
import './RecommendedProfessionTableRow.scss';

type RecommendedProfessionTableRowProps = {
    recommendedProfession: RecommendedProfession
    ranking: number
}

function RecommendedProfessionTableRow({ recommendedProfession, ranking }: RecommendedProfessionTableRowProps) {

    const [showDetail, setShowDetail] = useState<boolean>(false);
    const { isMobile, isTablet } = useScreenDetector();

    return (

        <tr className={`table-row ${showDetail ? 'opened' : ''}`} tabIndex={0} onClick={() => setShowDetail(!showDetail)}>

            <td className={`td-ranking ${showDetail ? '' : 'align-middle'}`}>
                <span className="number">{ranking}</span>
            </td>

            <td className={`td-profession ${showDetail ? '' : 'align-middle'}`}>
                <div className='d-flex flex-column'>
                    <h5 className="mb-0">{recommendedProfession.name}</h5>
                    {showDetail &&
                        <>
                            <p className='td-description my-3'>{removeHtmlTagsFromString(recommendedProfession.description)}</p>
                            <LinkButton text={t('professions:overview:professionCardBtn')} displayType='secondary' icon={'arrow-right'} to={'/professions/' + recommendedProfession.id} />
                        </>
                    }
                </div>
            </td>

            <td className="td-recommended text-center align-middle">
                <PercentageRadialChart
                    percentage={recommendedProfession.percentRecommended}
                    title={`Overall recommended percentage for profession ${recommendedProfession.name}`}
                    dimension={60}
                    strokeWidth={20}
                    fontSize={40} />
            </td>

            {!(isMobile || isTablet) &&
                recommendedProfession.attributeCategoryRecommendations.map((attributeCategory, index) => (

                    <td key={index} className={`text-center text-muted td-attribute-category ${showDetail ? 'align-middle' : 'align-middle'}`}>
                        <div className="d-flex flex-column">
                            <span className={`${showDetail ? 'fw-bold pb-3' : ''}`}>
                                {attributeCategory.percentRecommended}%
                            </span>
                        </div>
                    </td>
                ))
            }

        </tr>
    )
}

export default RecommendedProfessionTableRow