import Button from '@/components/button/Button';
import DateControl, { DateControlType } from '@/components/DateControl';
import VolunteerExperienceSearchBar from '@/components/search/VolunteerExperienceSearchBar';
import { ProfileContext } from '@/context/ProfileContext';
import { PROFILE_DATE_FORMAT } from '@/general/constants';
import { useMissingProfileCardRequirements } from '@/hooks/useMissingProfileCardRequirements';
import { Profile } from '@/profile/model/profile';
import { VolunteerExperience } from '@/profile/model/volunteer-experience';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { createRef, useContext, useState } from 'react';
import { Card, CloseButton, Col, Form, Modal, Row } from 'react-bootstrap';
import './ProfileCard.scss';

function ProfileVolunteerExperiences() {

    const profileContext = useContext(ProfileContext);
    const [showEditModal, setShowEditModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const formRef = createRef<HTMLFormElement>();
    const { isRequired } = useMissingProfileCardRequirements(['volunteerExperiences']);

    const openModal = () => {
        setValidated(false);
        setShowEditModal(true);
    }

    const closeModal = () => {
        setShowEditModal(false);
        profileContext.updateChanges(profileContext.profile);
    };

    const saveChanges = () => {
        setValidated(true);
        if (formRef.current?.checkValidity()) {
            profileContext.saveProfile(profileContext.tempChanges)
                .then(() => { closeModal(); })
                .catch(() => { });
        }
    };

    const updateTempChanges = (index, propertyName, newValue) => {

        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            volunteerExperiences: profileContext.tempChanges.volunteerExperiences.map((item, i) => i === index ? { ...item, [propertyName]: newValue } : item)
        };

        profileContext.updateChanges(updatedProfile);
    };

    const setVolunteerExperience = (index: number, volunteerExperience: VolunteerExperience) => {

        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            volunteerExperiences: profileContext.tempChanges.volunteerExperiences.map((item, i) => i === index
                ? { ...item, id: volunteerExperience?.id, name: volunteerExperience?.name }
                : item)
        };

        profileContext.updateChanges(updatedProfile);
    }

    const addVolunteerExperience = () => {
        const updatedVolunteerExperiences = [...profileContext.tempChanges.volunteerExperiences, {} as VolunteerExperience];
        profileContext.updateChanges({ ...profileContext.tempChanges, volunteerExperiences: updatedVolunteerExperiences });
    }

    const removeVolunteerExperience = (index: number) => {
        const updatedVolunteerExperiences = profileContext.tempChanges.volunteerExperiences.filter((item, i) => i !== index);
        profileContext.updateChanges({ ...profileContext.tempChanges, volunteerExperiences: updatedVolunteerExperiences });
    }

    return (
        <>
            <Card id="volunteerExperiences-card" className="profile-card mb-4">
                <Card.Header>
                    <Card.Title>{t('profile:volunteerExperiences:title')}</Card.Title>
                    <motion.button whileTap={{ scale: 0.85 }} onClick={() => openModal()}><i className="fa-solid fa-pen" /></motion.button>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        {profileContext.profile?.volunteerExperiences?.map((item, i) => (
                            <div className='card-item' key={i}>
                                <h3 className='title'>{item.name}</h3>
                                <p className='subtitle'>{item.organisationName}</p>
                                <p className='time-period'>
                                    <span>{(item.from === undefined || !dayjs(item.from).isValid()) ? t('profile:general:past') : dayjs(item.from).format(PROFILE_DATE_FORMAT)}</span>
                                    <span> - </span>
                                    <span>{(item.to === undefined || !dayjs(item.to).isValid()) ? t('profile:general:present') : dayjs(item.to).format(PROFILE_DATE_FORMAT)}</span>
                                </p>
                            </div>
                        ))}

                        {profileContext.profile?.volunteerExperiences?.length === 0 &&
                            <>
                                {isRequired
                                    ? <div className='profile-completion-required'>
                                        <p>{t('profile:general:missingRequirements')}</p>
                                        <i className="icon fa-solid fa-arrow-turn-up"></i>
                                    </div>
                                    :
                                    <div className='card-item'>
                                        <p>{t('profile:volunteerExperiences:noVolunteerExperiences')}</p>
                                    </div>
                                }
                            </>
                        }
                    </Card.Text >
                </Card.Body>
            </Card>

            <Modal className='wihv-modal wihv-modal--profile' size='lg' show={showEditModal} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('profile:volunteerExperiences:title')}</Modal.Title>
                    <CloseButton variant='white' onClick={() => closeModal()} />
                </Modal.Header>
                <Modal.Body className='p-3 p-md-4'>
                    <Form ref={formRef} validated={validated} noValidate>

                        {profileContext.tempChanges?.volunteerExperiences?.length === 0 &&
                            <div className='text-center'>
                                <p>{t('profile:volunteerExperiences:noVolunteerExperiences')}</p>
                            </div>
                        }

                        {profileContext.tempChanges?.volunteerExperiences?.map((item, index) => (
                            <Row className='wihv-modal__row p-2 p-md-3' key={index}>
                                <Col sm={12} className="p-0">
                                    <Form.Group as={Row} className="mt-0">
                                        <Col className="mt-2">
                                            <VolunteerExperienceSearchBar required={true} selectedVolunteerExperience={item} onVolunteerExperienceChange={selectedVolunteerExperience => setVolunteerExperience(index, selectedVolunteerExperience)} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-0">
                                        <Col className="mt-2">
                                            <Form.Label><span>{t('profile:volunteerExperiences:labelOrganisationName')}</span><span className='required'>*</span></Form.Label>
                                            <Form.Control type="text" placeholder="" value={item.organisationName ?? ''} onChange={e => updateTempChanges(index, 'organisationName', e.target.value)} required />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-0">
                                        <Col className="mt-2">
                                            <Form.Label><span>{t('profile:general:startdate')}</span></Form.Label>
                                            <DateControl controlType={DateControlType.MonthAndYear} value={item.from?.toString()} onChange={e => updateTempChanges(index, 'from', e.target.value)} />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mt-0">
                                        <Col className="mt-2">
                                            <Form.Label><span>{t('profile:general:enddate')}</span></Form.Label>
                                            <DateControl controlType={DateControlType.MonthAndYear} minValue={item.from?.toString()} value={item.to?.toString()} onChange={e => updateTempChanges(index, 'to', e.target.value)} />
                                        </Col>
                                    </Form.Group>
                                </Col>
                                <button type='button' onClick={() => removeVolunteerExperience(index)} className="wihv-modal__row-delete"><i className="fa-solid fa-trash-can" /></button>
                            </Row>
                        ))}
                    </Form>
                    <Row>
                        <Col sm={12} className='mt-3 text-center'>
                            <Button displayType='secondary' text={t('profile:volunteerExperiences:addVolunteerExperienceBtn')} icon='plus' onClick={() => addVolunteerExperience()} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button displayType='secondary' text={t('general:cancel')} onClick={() => closeModal()} />
                    <Button displayType='primary' text={t('general:save')} loading={profileContext.requestInProgress} onClick={() => saveChanges()} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default ProfileVolunteerExperiences