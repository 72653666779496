
import AnchorButton from "@/components/button/AnchorButton";
import Button from "@/components/button/Button";
import FormCard from "@/components/cards/FormCard";
import ConfirmModal from "@/components/confim-modal/ConfirmModal";
import { ConfigContext } from "@/context/ConfigContext";
import { AD_TOKEN_CLAIMS_IDENTITY_PROVIDER, IDENTITY_PROVIDER_LINKEDIN_NAME, IDENTITY_PROVIDER_LINKEDIN_PASSWORD_RESET_URL } from "@/general/constants";
import useAuthenticatedRequest from "@/hooks/useAuthenticatedRequest";
import useCurrentAccount from "@/hooks/useCurrentAccount";
import { useMsal } from "@azure/msal-react";
import { t } from "i18next";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { mutate } from "swr";

function AccountSettings() {

    const { instance } = useMsal();
    const { currentAccount } = useCurrentAccount();
    const authenticatedRequest = useAuthenticatedRequest();
    const configContext = useContext(ConfigContext);
    const identityProvider = currentAccount?.idTokenClaims[AD_TOKEN_CLAIMS_IDENTITY_PROVIDER];

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const handleDelete = () => {

        setShowConfirmModal(false);
        setIsSubmitting(true);

        authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.profile.detail, 'DELETE')
            .then(() => {
                toast.success(t('settings:toasts:deleteProfileSuccess'));
                mutate(() => true, undefined, false);
                instance.logoutRedirect();
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('settings:toasts:deleteProfileFailed') + ' ' + error.message);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    return (
        <>
            <FormCard title={t('settings:account:title')}>
                <p>{t('settings:account:description')}</p>

                <div className="mt-4 mb-5">
                    <div className="h5 font-bold mb-2">{t('settings:account:deleteProfileTitle')}</div>
                    <p className="mb-3">{t('settings:account:deleteProfileDescription')}</p>
                    <Button text={t('settings:account:deleteProfileBtn')} onClick={() => setShowConfirmModal(true)} displayType="secondary" icon="trash-can" />
                </div>

                <div className="">
                    <div className="h5 font-bold mb-2">{t('settings:account:changePasswordTitle')}</div>
                    <p className="mb-3">
                        {
                            identityProvider === IDENTITY_PROVIDER_LINKEDIN_NAME ?
                                t('settings:account:changePasswordDescriptionLinkedIn') :
                                t('settings:account:changePasswordDescription')
                        }
                    </p>
                    <AnchorButton
                        target="_blank"
                        rel="noopener noreferrer"
                        icon="external-link"
                        displayType="secondary"
                        text={t('settings:account:changePasswordBtn')}
                        href={identityProvider === IDENTITY_PROVIDER_LINKEDIN_NAME
                            ? IDENTITY_PROVIDER_LINKEDIN_PASSWORD_RESET_URL
                            : configContext.configBody.ad.changePasswordUrl
                        } />
                </div>
            </FormCard>

            <ConfirmModal
                title={t('settings:account:deleteProfileTitle')}
                description={t('settings:account:confirmDeleteProfileDescription')}
                showModal={showConfirmModal}
                isSubmitting={isSubmitting}
                onConfirm={handleDelete}
                onClose={() => setShowConfirmModal(false)} />
        </>
    )
}

export default AccountSettings