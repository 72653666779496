import axios from "axios";
import {APIConfig} from "@/context/ConfigContext";

export function getTranslations(apiConfig: APIConfig): Promise<any> {

    return axios.get(apiConfig.publicBaseUrl + apiConfig.endpoints.general.translations, 
        {
            headers: {
                'Accept': 'application/json',
                'Ocp-Apim-Subscription-Key': apiConfig.apiKey
            }
        })
        .then(response => {
            return response.data;
        })
        .catch(e => console.error(`Failed to load translations`, e))
}