import { YOUTUBE_NOCOOKIE_URL } from '@/general/constants';
import { useRef } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import './YoutubeVideoComponent.scss';

interface YoutubeVideoComponentProps {
    videoId: string;
    closedCaptions?: boolean;
    controls?: boolean;
    autoplay?: boolean;
}

function YoutubeVideoComponent({ videoId, closedCaptions = false, controls = true, autoplay = false }: YoutubeVideoComponentProps) {

    const playerRef = useRef<any>(null);
    const savedLanguage = localStorage.getItem('selectedLanguage');

    const opts: YouTubeProps['opts'] = {
        host: YOUTUBE_NOCOOKIE_URL,
        width: '100%',
        height: '100%',
        playerVars: {
            autoplay: autoplay ? 1 : 0,
            controls: controls ? 1 : 0,
            rel: 0,
            cc_load_policy: closedCaptions ? 1 : 0,
            cc_lang_pref: savedLanguage,
            hl: savedLanguage,
            iv_load_policy: 3
        },
    };

    const onPlayerReady = (event: { target: any }) => {
        playerRef.current = event.target;
    };

    return (
        <div className='youtube-video-component'>
            <YouTube className="youtube-video" iframeClassName='youtube-video-ifame' videoId={videoId} opts={opts} onReady={onPlayerReady} />
        </div>
    );
}

export default YoutubeVideoComponent;