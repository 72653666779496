import { useAttributeCategoryTranslation } from "@/general/i18n/translation-helpers";
import { AttributeCategory } from "@/general/model/attribute-category";
import './FeedbackAttributes.scss';
import { AttributeFeedback } from "@/general/model/attribute-feedback";


type FeedbackAttributesProps = {
    attributes: AttributeFeedback[];
    category: AttributeCategory;
};

function FeedbackAttributes({ category, attributes }: FeedbackAttributesProps) {
    const { translateAttributeCategory } = useAttributeCategoryTranslation();

    return (
        <div className={`feedback-attributes category-${category}`}>
            <header>
                <h2 className="title">{translateAttributeCategory(category)}</h2>
            </header>

            {attributes.length > 0 ? (
                <div className="attributes">
                    {attributes.map((attribute) => (
                        <span
                            key={attribute.attributeId}
                            className={`attribute ${
                                attribute.value === "-1"
                                    ? 'negative-feedback'
                                    : attribute.value === "0"
                                        ? 'neutral-feedback'
                                        : attribute.value === "1"
                                            ? 'very-good-feedback'
                                            : ''
                            }`}
                            tabIndex={0}
                        >
                            {attribute.attributeName}
                            {attribute.value === "-1" && (
                                <i className="icon far fa-star" title="Bad feedback (Empty star)" />
                            )}
                            {attribute.value === "0" && (
                                <i className="icon fas fa-star-half-alt" title="Neutral feedback (Half star)" />
                            )}
                            {attribute.value === "1" && (
                                <i className="icon fas fa-star" title="Very Good feedback (Filled star)" />
                            )}
                        </span>
                    ))}
                </div>
            ) : (
                <div className="empty-category-message">
                    <p>Je hebt nog geen kenmerken in deze categorie.</p>
                </div>
            )}
        </div>
    );
}

export default FeedbackAttributes;
