import LinkButton from "@/components/button/LinkButton"
import { removeHtmlTagsFromString } from "@/general/utilities/RemoveHtmlTagsFromString"
import { RecommendedProfession } from "@/professions/model/recommended-profession"
import { t } from "i18next"
import './RecommendedProfessionsPodium.scss'

type RecommendedProfessionsPodiumProps = {
    recommendedProfessions: RecommendedProfession[]
}

function RecommendedProfessionsPodium({ recommendedProfessions }: RecommendedProfessionsPodiumProps) {

    return (
        <div className="recommended-professions-podium">
            <div className={`podium-item podium-item--second`}>
                <div className="ranking">2</div>
                <div className="percentage">{recommendedProfessions[1]?.percentRecommended}%</div>
                <div className="content">
                    <h2 className="title">{recommendedProfessions[1]?.name}</h2>
                    <div className="description">{removeHtmlTagsFromString(recommendedProfessions[1]?.description)}</div>
                </div>
                <div className="cta">
                    <LinkButton text={t('professions:overview:professionCardBtn')} displayType="secondary" icon="arrow-right" to={`/professions/${recommendedProfessions[1].id}`} />
                </div>
            </div>
            <div className={`podium-item podium-item--first`}>
                <div className="ranking">1</div>
                <div className="percentage">{recommendedProfessions[0]?.percentRecommended}%</div>
                <div className="content">
                    <h2 className="title">{recommendedProfessions[0]?.name}</h2>
                    <div className="description">{removeHtmlTagsFromString(recommendedProfessions[0]?.description)}</div>
                </div>
                <div className="cta">
                    <LinkButton text={t('professions:overview:professionCardBtn')} icon="arrow-right" to={`/professions/${recommendedProfessions[1].id}`} />
                </div>
            </div>
            <div className={`podium-item podium-item--third`}>
                <div className="ranking">3</div>
                <div className="percentage">{recommendedProfessions[2]?.percentRecommended}%</div>
                <div className="content">
                    <h2 className="title">{recommendedProfessions[2]?.name}</h2>
                    <div className="description">{removeHtmlTagsFromString(recommendedProfessions[2]?.description)}</div>
                </div>
                <div className="cta">
                    <LinkButton text={t('professions:overview:professionCardBtn')} displayType="secondary" icon="arrow-right" to={`/professions/${recommendedProfessions[1].id}`} />
                </div>
            </div>
        </div>
    )
}

export default RecommendedProfessionsPodium