import { Breadcrumb } from "@/general/model/breadcrumb";
import { useState } from "react";
import { BreadcrumbsContext } from "./BreadcrumbsContext";

export const BreadcrumbsProvider = ({ children }) => {

    // TODO: Remove breadcrumbs state and create hirachical breadcrumbs based on the current route
    // add more routes to same entry point like: /learning/learningpath/:id & /professions/:id/learningpath/:id
    
    const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);

    return (
        <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
            {children}
        </BreadcrumbsContext.Provider>
    );
};