import SkeletonSwiperComponent from '@/components/swiper-component/SkeletonSwiperComponent';
import { ConfigContext } from '@/context/ConfigContext';
import { useIsAuthenticated } from '@azure/msal-react';
import { useContext } from 'react';
import useSWR from 'swr';
import { ProfessionCategory } from '../model/profession-category';
import './ProfessionCategories.scss';
import ProfessionSwiper from './ProfessionSwiper';

function ProfessionCategories() {

    const isAuthenticated = useIsAuthenticated();
    const configContext = useContext(ConfigContext);

    const baseUrl = isAuthenticated ? configContext.configBody.api.baseUrl : configContext.configBody.api.publicBaseUrl;
    const { data: categories, isLoading, error, mutate } = useSWR<ProfessionCategory[]>(baseUrl + configContext.configBody.api.endpoints.professions.categories);

    return (
        <div className='profession-categories'>
            {categories?.map((category, index) => {
                return (
                    <div key={index} className="category">
                        <h2 className="title">{category.title}</h2>
                        <ProfessionSwiper professions={category.professions} />
                    </div>
                )
            })}

            {
                isLoading &&
                <>
                    <SkeletonSwiperComponent />
                    <SkeletonSwiperComponent />
                </>
            }
        </div>
    )
}

export default ProfessionCategories