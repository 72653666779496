import SkeletonSwiperComponent from '@/components/swiper-component/SkeletonSwiperComponent';
import { ConfigContext } from '@/context/ConfigContext';
import { useContext } from 'react';
import useSWR from 'swr';
import { MeasurementCategory } from '../model/measurement-category';
import './MeasurementCategories.scss';
import MeasurementSwiper from './MeasurementSwiper';

function MeasurementCategories() {

    const configContext = useContext(ConfigContext);
    const { data: categories, isLoading } = useSWR<MeasurementCategory[]>(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.measurements.categories);

    return (
        <div className='measurement-categories'>
            {categories?.map((category, index) => {
                return (
                    <div key={index} className="category">
                        <h2 className="title">{category.title}</h2>
                        <MeasurementSwiper measurements={category.measurements} />
                    </div>
                )
            })}

            {
                isLoading &&
                <>
                    <SkeletonSwiperComponent />
                    <SkeletonSwiperComponent />
                </>
            }
        </div>
    )
}

export default MeasurementCategories