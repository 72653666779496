
import { FilterOption } from '@/components/filter-component/FilterComponent';
import { ConfigContext } from '@/context/ConfigContext';
import { useOriginTypeTranslation } from '@/general/i18n/translation-helpers';
import { Attribute } from '@/general/model/attribute';
import { DataOrigin } from '@/general/model/data-origin';
import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import useSWR from 'swr';

const useProfileAttributes = () => {

    const configContext = useContext(ConfigContext);
    const { translateOriginType } = useOriginTypeTranslation();
    const { data, isLoading, error, mutate } = useSWR<Attribute[]>(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.attributes.list, { shouldRetryOnError: false });

    const [profileAttributes, setProfileAttributes] = useState<Attribute[]>([]);
    const [filteredAttributes, setFilteredAttributes] = useState<Attribute[]>([]);
    const [filterOptions, setFilterOptions] = useState<FilterOption[]>([]);

    const [searchterm, setSearchterm] = useState<string>('');
    const [filter, setFilter] = useState<FilterOption[]>([]);

    useEffect(() => {
        if (!data) return;
        setProfileAttributes(data);
    }, [data]);

    useEffect(() => {
        buildFilterOptions();
    }, [profileAttributes]);

    useEffect(() => {
        sortAndFilterAttributes();
    }, [profileAttributes, searchterm, filter]);

    const updateSearchterm = useCallback(
        debounce((value) => setSearchterm(value), 400), []
    );

    const updateFilters = (value: FilterOption[]) => {
        setFilter(value);
    }

    const sortAndFilterAttributes = () => {

        let attributeResult = [...profileAttributes];

        if (searchterm) {
            attributeResult = attributeResult.filter((attr) =>
                attr.name.toLowerCase().includes(searchterm.toLowerCase())
            );
        }

        if (filter.length > 0) {
            attributeResult = attributeResult.filter((attr) =>
                attr.origins.some((origin) =>
                    filter.some((filter) => origin.displayValue === filter.value)
                )
            );
        }

        setFilteredAttributes(attributeResult);
    }

    const buildFilterOptions = () => {
        const uniqueOrigins = new Map<string, DataOrigin>();

        profileAttributes.forEach(attribute => {
            attribute.origins?.forEach(origin => {
                if (!uniqueOrigins.has(origin.displayValue)) {
                    uniqueOrigins.set(origin.displayValue, origin);
                }
            });
        });

        const filterOptions: FilterOption[] = Array.from(uniqueOrigins.values()).map(origin => ({
            id: origin.displayValue,
            label: origin.displayValue,
            category: `${translateOriginType(origin.type)}`,
            value: origin.displayValue,
            type: 'checkbox'
        }));

        setFilterOptions(filterOptions);
    }

    return { isLoading, error, profileAttributes, filteredAttributes, filterOptions, updateSearchterm, updateFilters, mutate };
}

export default useProfileAttributes;