
import useFavoriteLearningResources from '@/hooks/useFavoriteLearningResources';
import { t } from 'i18next';
import { Container } from 'react-bootstrap';
import LearningResourceSwiper from './LearningResourceSwiper';

function FavoriteLearningResources() {

    const { favoriteLearningResources } = useFavoriteLearningResources();

    return (
        favoriteLearningResources?.length > 0 &&
        <Container fluid className="pt-3 pb-4 position-relative">
            <Container className="position-relative">
                <div>
                    <h2 className="d-flex align-items-center pb-3">
                        <span>{t('learning:overview:favoritesTitle')}</span>
                        <i className='fas fa-star fa-xs ms-2' />
                    </h2>
                    <LearningResourceSwiper resources={favoriteLearningResources} />
                </div>
            </Container>
        </Container>
    )
}

export default FavoriteLearningResources