import { t } from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import './UsefulLinks.scss';
import { COOKIE_POLICY_LINK, PRIVACY_POLICY_LINK, TERMS_AND_CONDITIONS_LINK } from '@/general/constants';

function UsefulLinks() {
    return (
        <Container className="useful-links mt-4">
            <Row>
                <Col>
                    <h4 className='title'>{t('settings:usefulLinks:title')}</h4>
                    <ul>
                        <li>
                            <a href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
                                {t('settings:usefulLinks:privacyPolicy')}
                            </a>
                        </li>
                        <li>
                            <a href={COOKIE_POLICY_LINK} target="_blank" rel="noopener noreferrer">
                                {t('settings:usefulLinks:cookiePolicy')}
                            </a>
                        </li>
                        <li>
                            <a href={TERMS_AND_CONDITIONS_LINK} target="_blank" rel="noopener noreferrer">
                                {t('settings:usefulLinks:termsAndConditions')}
                            </a>
                        </li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default UsefulLinks;