import { CookiePreferencesContext } from "@/context/CookiePreferencesContext";
import { motion } from "framer-motion";
import { t } from "i18next";
import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Button from "../button/Button";
import CookiePreferencesModal from "../cookie-preferences-modal/CookiePreferencesModal";
import './CookieConsentBanner.scss';
import { PRIVACY_POLICY_LINK, TERMS_AND_CONDITIONS_LINK } from "@/general/constants";

function CookieConsentBanner() {

    const [showBanner, setShowBanner] = useState(false);
    const [showCookiePreferencesModal, setShowCookiePreferencesModal] = useState(false);
    const { preferences, acceptAll, denyAll } = useContext(CookiePreferencesContext);

    useEffect(() => {
        setShowBanner(preferences == null);
    }, [preferences]);

    const bannerVariants = {
        hidden: { y: "100vh", opacity: 0 },
        visible: { y: 0, opacity: 1, transition: { duration: 0.8, ease: "linear", delay: 0.4 } },
    };

    return showBanner ? (
        <>
            <motion.div role="dialog"
                aria-labelledby="cookie-title"
                className="cookie-banner-wrapper"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={bannerVariants}>
                <Container fluid className="cookie-banner">
                    <Container className="content">
                        <h3 id="cookie-title" className="title">{t('cookieConsent:title')}</h3>
                        <p className="description">{t('cookieConsent:description')}</p>
                        <img className="image" src="/assets/img/WIHV_3D_Visual_Cookie.png" alt="" />
                        <div className="actions">
                            <Button className="accept-button" onClick={acceptAll} text={t('cookieConsent:acceptAll')} displayType="secondary" />
                            <Button className="deny-button" onClick={denyAll} text={t('cookieConsent:denyAll')} displayType="secondary" />
                            <Button className="manage-button" onClick={() => setShowCookiePreferencesModal(true)} text={t('cookieConsent:managePreferences')} displayType="tertiary" />
                        </div>
                    </Container>
                </Container>
                <Container fluid className="cookie-footer">
                    <Container className="content">
                        <a target="_blank" aria-label={t('cookieConsent:privacyPolicyLabel')} href={PRIVACY_POLICY_LINK}>{t('cookieConsent:privacyPolicy')}</a>
                        <a target="_blank" aria-label={t('cookieConsent:termsAndConditionsLabel')} href={TERMS_AND_CONDITIONS_LINK}>{t('cookieConsent:termsAndConditions')}</a>
                    </Container>
                </Container>
            </motion.div>
            <CookiePreferencesModal showModal={showCookiePreferencesModal} onClose={() => { setShowCookiePreferencesModal(false) }} />
        </>
    ) : null;
}

export default CookieConsentBanner