import { LayoutContext } from '@/context/LayoutContext';
import { PlatformSettingsContext } from '@/context/PlatformSettingsContext';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './NavigationDrawer.scss';
import NavigationDrawerItem from './NavigationDrawerItem';
import { t } from 'i18next';
import useCurrentAccount from '@/hooks/useCurrentAccount';
import { PERMISSIONS } from '@/general/constants';

function NavigationDrawer() {

    const { navigationFolded, navigationWidth, isMobile, setNavigationFolded } = useContext(LayoutContext);
    const platformSettingsContext = useContext(PlatformSettingsContext);
    const { hasPermission } = useCurrentAccount();

    useEffect(() => {
        if (isMobile && !navigationFolded) {
            document.body.classList.add('noscroll');
        }
        else {
            document.body.classList.remove('noscroll');
        }
    }, [navigationFolded, isMobile]);

    return (
        <motion.div
            className={`navigation-drawer ${isMobile ? 'mobile' : ''} ${navigationFolded ? 'folded' : 'unfolded'}`}
            animate={{ width: navigationWidth }}
            transition={{ duration: 0.2 }}
        >

            <Link to='/' className='logo'>
                <AnimatePresence>
                    {navigationFolded
                        ? <img src={`/assets/img/WIHV-Icon.png`} alt='' />
                        : <motion.img
                            alt=''
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.2, delay: 0.1 }}
                            src={`/assets/img/werk-in-het-vooruitzicht-fullcolor.svg`}
                        />
                    }
                </AnimatePresence>
            </Link>

            {/* TODO: Add logo of partner when available */}
            {/* {platformSettingsContext.logo &&
                    <>
                        <span className='divider' />
                        <div className='logo logo-partner'>
                            <img src={platformSettingsContext.logo} alt='' />
                        </div>
                    </>
                } */}

            <nav className="navigation-drawer-nav">
                <ul className='navigation-drawer-links'>
                    <NavigationDrawerItem icon='house' title={t('navigation:dashboard')} link='/' />
                    <NavigationDrawerItem icon='user' title={t('navigation:profile')} link='/profile' />
                    <NavigationDrawerItem icon='id-card' title={t('navigation:development')} link='/development' />
                    <NavigationDrawerItem icon='list-check' title={t('navigation:measurements')} link='/measurements' />
                    <NavigationDrawerItem icon='laptop-code' title={t('navigation:professions')} link='/professions' />
                    <NavigationDrawerItem icon='ranking-star' title={t('navigation:professionsGuide')} link='/professions-guide' />
                    <NavigationDrawerItem icon='graduation-cap' title={t('navigation:learning')} link='/learning' />
                </ul>
            </nav>

            <div className='navigation-footer'>
                {/* TODO: It now checks on profession recommended permission but should be a general check on paid profile */}
                {!hasPermission(PERMISSIONS.PROFESSIONS_RECOMMENDED) &&
                    (
                        navigationFolded
                            ?
                            <ul className='navigation-drawer-links'>
                                <NavigationDrawerItem icon='circle-up' title={t('navigation:vouchersCTATitle')} link='/vouchers' />
                            </ul>
                            :
                            <section className='account-cta'>
                                <h3 className='title'>{t('navigation:vouchersCTATitle')}</h3>
                                <p className='description'>{t('navigation:vouchersCTADescription')}</p>
                                <Link to={'/vouchers'} className='link'>
                                    <i className='fas fa-arrow-right' />
                                </Link>
                            </section>
                    )
                }
                <button className='toggle' onClick={() => setNavigationFolded(!navigationFolded)}>
                    <i className={`fas fa-angles-${navigationFolded ? 'right' : 'left'}`} />
                </button>
            </div>
        </motion.div>
    );
}

export default NavigationDrawer;
