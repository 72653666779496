import LinkButton from "@/components/button/LinkButton";
import SwiperLockedComponent from "@/components/swiper-locked-component/SwiperLockedComponent";
import { PERMISSIONS } from "@/general/constants";
import useCurrentAccount from "@/hooks/useCurrentAccount";
import useProfileCompletionRequirements from "@/hooks/useProfileCompletionRequirements";
import useRecommendedProfessions from "@/hooks/useRecommendedProfessions";
import { t } from "i18next";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProfessionSwiper from "./ProfessionSwiper";

function RecommendedProfessions() {

    const { totalCompletion } = useProfileCompletionRequirements();
    const { recommendedProfessions, mutate } = useRecommendedProfessions();
    const { hasPermission } = useCurrentAccount();

    useEffect(() => {
        mutate();
    }, []);

    return (
        <>
            {totalCompletion == 100 &&
                <Container fluid className="pt-3 pb-4">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="pb-3 pt-3">
                                    <h2>{t('professions:overview:recommendedTitle')}</h2>
                                </div>
                                <div>
                                    <ProfessionSwiper professions={recommendedProfessions} showIndex={true} />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }

            {totalCompletion < 100 &&
                <Container fluid className="pt-3 pb-4 position-relative">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="pb-3 pt-3 position-relative">
                                    <h2>{t('professions:overview:recommendedTitle')}</h2>
                                </div>
                                {!hasPermission(PERMISSIONS.PROFESSIONS_RECOMMENDED)
                                    ?
                                    <SwiperLockedComponent>
                                        <h3 className="pb-2">{t('CTA:upgradeRecommendedProfessions:title')}</h3>
                                        <p className="pb-3">{t('CTA:upgradeRecommendedProfessions:description')}</p>
                                        <LinkButton text={t('CTA:upgradeRecommendedProfessions:btn')} icon='chevron-right' to={'/vouchers'} />
                                    </SwiperLockedComponent>
                                    :
                                    <SwiperLockedComponent>
                                        <h3 className="pb-2">{t('CTA:profileIncomplete:title')}</h3>
                                        <p className="pb-3">{t('CTA:profileIncomplete:description')}</p>
                                        <LinkButton text={t('CTA:profileIncomplete:btn')} icon='chevron-right' to={'/profile'} />
                                    </SwiperLockedComponent>
                                }
                            </Col>
                        </Row>
                    </Container>
                </Container>
            }
        </>
    )
}

export default RecommendedProfessions