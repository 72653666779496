import Button from '@/components/button/Button'
import { ConfigContext } from '@/context/ConfigContext'
import { Feedback } from '@/general/model/feedback'
import { t } from 'i18next'
import { useContext, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import useSWR from 'swr'
import FeedbackStatusOverview from './FeedbackStatusOverview'
import InviteFeedbackProvidersModal from './InviteFeedbackProvidersModal'
import './ProfileEvaluation.scss'

function ProfileEvaluation() {

    const configContext = useContext(ConfigContext);
    const endpoint = configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.feedbacks.list;
    const { data: feedbacks, isLoading, error, mutate } = useSWR<Feedback[]>(endpoint);

    const [showFeedbackProvidersModal, setShowFeedbackProvidersModal] = useState(false);

    return (
        <div className='profile-evaluation'>
            <Row>
                <Col sm={12} className='feedback'>
                    <h3 className="title">{t('development:evaluation:feedbackTitle')}</h3>
                    <p className='description'>{t('development:evaluation:feedbackDescription')}</p>
                    <Button text={t('development:evaluation:feedbackBtn')} displayType='secondary' icon='user-check' onClick={() => { setShowFeedbackProvidersModal(true) }} />

                    {!isLoading && feedbacks?.length > 0 &&
                        <FeedbackStatusOverview feedbacks={feedbacks} onSuccess={() => mutate()} />
                    }
                </Col>
            </Row>

            <InviteFeedbackProvidersModal
                showModal={showFeedbackProvidersModal}
                onClose={() => setShowFeedbackProvidersModal(false)}
                onSuccess={() => { setShowFeedbackProvidersModal(false); mutate(); }}
            />
        </div>
    )
}

export default ProfileEvaluation