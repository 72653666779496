import SkeletonSwiperComponent from '@/components/swiper-component/SkeletonSwiperComponent';
import { ConfigContext } from '@/context/ConfigContext';
import { useContext } from 'react';
import useSWR from 'swr';
import { LearningResourceCategory } from '../models/learning-resource-category';
import './LearningResourceCategories.scss';
import LearningResourceSwiper from './LearningResourceSwiper';

function LearningResourceCategories() {

    const configContext = useContext(ConfigContext);
    const { data: categories, isLoading, error, mutate } = useSWR<LearningResourceCategory[]>(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.learning.categories);

    return (
        <div className='learningResource-categories'>
            {categories?.map((category, index) => {
                return (
                    <div key={index} className="category">
                        <h2 className="title">{category.title}</h2>
                        <LearningResourceSwiper resources={category.resources} />
                    </div>
                )
            })}

            {
                isLoading &&
                <>
                    <SkeletonSwiperComponent />
                    <SkeletonSwiperComponent />
                </>
            }
        </div>
    )
}

export default LearningResourceCategories