import Button from '@/components/button/Button';
import { ConfigContext } from '@/context/ConfigContext';
import useAuthenticatedRequest from '@/hooks/useAuthenticatedRequest';
import { t } from 'i18next';
import { createRef, useContext, useEffect, useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import './RedeemVoucherModal.scss';

type RedeemVoucherModalProps = {
    showModal: boolean;
    onClose: () => void;
    onSuccess?: () => void;
}

function RedeemVoucherModal({ showModal, onClose, onSuccess }: RedeemVoucherModalProps) {

    const configContext = useContext(ConfigContext);
    const authenticatedRequest = useAuthenticatedRequest();
    const formRef = createRef<HTMLFormElement>();
    const [validated, setValidated] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [voucherCode, setVoucherCode] = useState<string>('');
    const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] = useState<boolean>(false);

    useEffect(() => {
        if (showModal) {
            setVoucherCode('');
            setValidated(false);
            setIsSubmitting(false);
        }
    }, [showModal]);

    const handleSubmit = () => {

        setValidated(true);

        if (formRef.current?.checkValidity()) {
            setIsSubmitting(true);

            authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.vouchers.redeem, 'POST', { 'code': voucherCode })
                .then(() => {
                    onClose();
                    toast.success(t('vouchers:toasts:redeemVoucherSuccess'));
                    onSuccess();
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(t('vouchers:toasts:redeemVoucherFailed') + ' ' + error.message);
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }
    }

    return (
        <Modal className="wihv-modal redeem-voucher-modal" show={showModal} onHide={() => onClose()} size='lg' backdrop="static" centered>
            <Modal.Header>
                <Modal.Title>{t('vouchers:redeemModal:title')}</Modal.Title>
            </Modal.Header>

            <Modal.Body className='content'>

                <p className='pb-3'>{t('vouchers:redeemModal:description')}</p>

                <Form ref={formRef} validated={validated} noValidate>
                    <Form.Group as={Row} className="mt-0">
                        <Col className="mt-2">
                            <Form.Label><span>{t('vouchers:redeemModal:labelVoucherCode')}</span><span className='required'>*</span></Form.Label>
                            <Form.Control type="text" placeholder={t('vouchers:redeemModal:placeholderVoucherCode')} value={voucherCode ?? ''} onChange={e => setVoucherCode(e.target.value)} required />
                        </Col>
                    </Form.Group>

                    <ul className='info'>
                        <li><i className='fas fa-circle-chevron-right me-2' />{t('vouchers:redeemModal:infoVoucherCode1')}</li>
                        <li><i className='fas fa-circle-chevron-right me-2' />{t('vouchers:redeemModal:infoVoucherCode2')}</li>
                    </ul>

                    <Form.Group as={Row} className="mt-3">
                        <Col className="mt-2">
                            {/* TODO: Add TERMS_AND_CONDITIONS_LINK & translations*/}
                            <Form.Check label={<>Ik accepteer de algemene voorwaarden <span className='required'>*</span></>} value={voucherCode ?? ''} onChange={e => setAcceptedTermsAndConditions(e.target.checked)} required />
                        </Col>
                    </Form.Group>
                </Form>

            </Modal.Body>

            <Modal.Footer>
                <Button text={t('vouchers:redeemModal:cancelBtn')} displayType='secondary' onClick={() => onClose()} />
                <Button text={t('vouchers:redeemModal:submitBtn')} loading={isSubmitting} onClick={() => handleSubmit()} />
            </Modal.Footer>
        </Modal>
    )
}

export default RedeemVoucherModal