import LearningResourceCard from '@/components/cards/LearningResourceCard';
import FilterComponent from '@/components/filter-component/FilterComponent';
import SearchComponent from '@/components/search/SearchComponent';
import SortComponent from '@/components/sort-component/SortComponent';
import useAllLearningResources from '@/hooks/useAllLearningResources';
import { t } from 'i18next';
import { useCallback, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import './LearningResourceList.scss';

function LearningResourceList() {

    const { resources, totalCount, isLoading, filterOptions, updateSearchterm, updateSorting, updateFilters, loadMore } = useAllLearningResources();

    const sortingOptions = [
        { label: t('learning:overview:listSortNameAsc'), value: 'name asc' }
    ];

    const observer = useRef<IntersectionObserver | null>(null);
    const lastEducationElementRef = useCallback(node => {
        if (isLoading || resources.length >= totalCount) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                loadMore();
            }
        })
        if (node) observer.current.observe(node);
    }, [isLoading]);

    return (
        <>
            <div className="education-list" id='list'>
                <Row>
                    <Col sm={12} className='header d-flex flex-column flex-lg-row justify-content-between align-items-center mb-5'>
                        <span className='d-flex flex-column pb-3 pb-lg-0'>
                            <h2 className='title'>{t('learning:overview:listTitle')}</h2>
                            <span className='result-count'>{t('learning:overview:listResultCount', { count: resources.length, total: totalCount })}</span>
                        </span>

                        <div className='filters d-flex gap-3 flex-column flex-md-row'>
                            <FilterComponent options={filterOptions} onFiltersChange={(value) => updateFilters(value)} />
                            <SortComponent options={sortingOptions} onSortChange={(value) => updateSorting(value)} />
                            <SearchComponent placeholder={t('learning:overview:listSearchPlaceholder')} onSearchChange={(value) => updateSearchterm(value)} />
                        </div>
                    </Col>
                </Row>

                {resources &&
                    <Row>
                        {resources.length > 0 &&
                            resources.map((resource, index) => (
                                <Col ref={index === resources.length - 1 ? lastEducationElementRef : null} sm={12} md={6} lg={4} key={resource.id} className='mb-5'>
                                    <LearningResourceCard resource={resource} />
                                </Col>
                            ))
                        }
                    </Row>
                }

                {isLoading &&
                    <Row>
                        <Col sm={12} className='py-4 text-center'>
                            <i className="fas fa-circle-notch fa-spin fa-3x"></i>
                        </Col>
                    </Row>
                }

                {resources.length == 0 && !isLoading &&
                    <Col sm={12} className='no-results'>
                        <p>{t('learning:overview:listSearchNoResults')}</p>
                    </Col>
                }
            </div>
        </>
    )
}

export default LearningResourceList