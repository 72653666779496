import { ConfigContext } from '@/context/ConfigContext';
import { DEFAULT_LANGUAGE } from '@/general/constants';
import { Profession } from '@/professions/model/profession';
import debounce from "debounce-promise";
import { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import AsyncCreatableSelect from 'react-select/async-creatable';
import { SingleValue } from "react-select/dist/declarations/src/types";
import { EscoProfessionSearchDocument } from './models/esco-profession-search-document';

type SearchBarProps = {
    profession?: Profession;
    required?: boolean;
    onProfessionChange: (s: Profession) => void;
}

export default function ProfessionSearchBar({profession, required = false, onProfessionChange}: SearchBarProps) {
    const {t} = useTranslation();
    const configContext = useContext(ConfigContext);

    const [selectedOption, setSelectedOption] = useState<SingleValue<any>[]>([]);

    useEffect(() => {

        if (profession?.id) {

            const initSelectedOption = {
                value: {
                    name: profession.name,
                    id: profession.id
                } as EscoProfessionSearchDocument,
                label: profession.name
            };

            setSelectedOption(initSelectedOption as unknown as SingleValue<any>[]);
        }
    }, [profession]);

    const handleCreatedOption = (inputValue: string) => {
        
        if (inputValue) {

            const selectedProfession = {
                name: inputValue,
                id: null
            } as Profession;

            setSelectedOption({label: inputValue, value: null} as unknown as SingleValue<any>[]);
            onProfessionChange(selectedProfession);
            return;
        }
    }

    const handleSelectedOption = (selectedOption: any) => {

        if (!selectedOption) {
            setSelectedOption([] as unknown as SingleValue<any>[]);
            onProfessionChange(null);
            return;
        }
        
        setSelectedOption(selectedOption);

        const selectedProfession = {
            name: selectedOption.label,
            id: selectedOption.value?.id
        } as Profession;
        
        onProfessionChange(selectedProfession);
    }

    const _loadProfessionSuggestions = (inputValue: string) => {

        const savedLanguage = localStorage.getItem('selectedLanguage') ?? DEFAULT_LANGUAGE;

        const body = {
            filter: `language eq '${savedLanguage}'`,
            select: 'name, id, skills, knowledge, description, professionName',
            search: inputValue,
            suggesterName: 'esco-profession-suggester',
            top: 15
        };

        return fetch(`${configContext.configBody.api.publicBaseUrl}${configContext.configBody.api.endpoints.search.escoProfessions}/suggest`, {
            credentials: "same-origin",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.api.apiKey
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error("Invalid response");
            })
            .then((result) => {
                let groupedResults = result.value.reduce((acc: any, item: EscoProfessionSearchDocument) => {
                    const key = item.professionName;
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    acc[key].push(item);
                    return acc;
                }, {});

                return Object.keys(groupedResults).map((key) => {
                    return {
                        label: key,
                        options: groupedResults[key].map((item: EscoProfessionSearchDocument) => {
                            return {
                                value: item,
                                label: item.name
                            };
                        })
                    };
                });
            })
            .catch(
                (error) => {

                });
    }

    const loadProfessionSuggestions = debounce(_loadProfessionSuggestions, 300);

    return (
        <Form.Group as={Row} className='profession-search'>
            <Col>
                <Form.Label>{t('profile:workExperiences:labelName')}{required && <span className='required'>*</span>}</Form.Label>
                <AsyncCreatableSelect
                    isMulti={false}
                    isClearable
                    placeholder={t('profile:workExperiences:placeholderSearch')}
                    value={selectedOption}
                    onChange={(s) => handleSelectedOption(s)}
                    onCreateOption={(s) => handleCreatedOption(s)}
                    defaultOptions={false}
                    loadOptions={loadProfessionSuggestions}
                    required={required}
                    hideSelectedOptions={true}
                    className='p-0'
                    formatCreateLabel={(inputValue) => <span role='button'>{t('profile:workExperiences:searchCreate')} "<span className='font-bold'>{inputValue}</span>"</span>}
                    noOptionsMessage={(e) => e.inputValue ? t('profile:workExperiences:searchNoResults') : null}
                    classNames={{
                        control: () => 'search-control'
                    }}
                    styles={{
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            border: state.isDisabled ? '' : 'none',
                            borderRadius: '25px',
                            padding: '0.5rem',
                            boxShadow: 'none'
                        }),
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            color: 'black',
                            cursor: 'pointer'
                        }),
                    }}/>
            </Col>
        </Form.Group>
    );
};