import ConfirmModal from "@/components/confim-modal/ConfirmModal";
import { ConfigContext } from "@/context/ConfigContext";
import { useFeedbackStatusTranslation } from "@/general/i18n/translation-helpers";
import { Feedback } from "@/general/model/feedback";
import { FeedbackStatus } from "@/general/model/feedback-status";
import useAuthenticatedRequest from "@/hooks/useAuthenticatedRequest";
import { t } from "i18next";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import './FeedbackStatusOverview.scss';
import EvaluationPopup from "./EvaluationPopup";
import Button from '@/components/button/Button';

type FeedbackStatusOverviewProps = {
    feedbacks: Feedback[]
    onSuccess?: () => void
}

function FeedbackStatusOverview({ feedbacks, onSuccess }: FeedbackStatusOverviewProps) {

    const configContext = useContext(ConfigContext);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedValidator, setSelectedValidator] = useState<Feedback>(null);
    const authenticatedRequest = useAuthenticatedRequest();
    const { translateFeedbackStatus } = useFeedbackStatusTranslation();
    const [selectedFeedback, setSelectedFeedback] = useState<Feedback>(null);
    const [showPopup, setShowPopup] = useState(false);

    const handleDelete = () => {

        setIsSubmitting(true);

        authenticatedRequest(configContext.configBody.api.baseUrl + configContext.configBody.api.endpoints.feedbacks.detail.replace('{id}', selectedValidator?.id), 'DELETE')
            .then(() => {
                toast.success(t('development:toasts:validatorDeleteSuccess'));
                setShowConfirmModal(false);
                onSuccess();
            })
            .catch((error) => {
                console.error(error);
                toast.error(t('development:toasts:validatorDeleteFailed') + ' ' + error.message);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    const handleShowEvaluation = (feedback: Feedback) => {
        setSelectedFeedback(feedback);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setSelectedFeedback(null);
    };    

    return (
        <>
            <div className="feedback-status-overview">
                <h3 className="title">{t('development:evaluation:feedbackStatusTitle')}</h3>
                <ul className='validator-list'>
                    {feedbacks?.map((item, index) => (
                        <li className='validator' key={index}>
                            <span>{item.name}</span>
                            <span className={`status ${item.status === FeedbackStatus.Completed && 'status--completed'}`}>
                                {translateFeedbackStatus(item.status)}
                            </span>

                            {item.status == FeedbackStatus.Pending &&
                                <button type='button' onClick={() => { setSelectedValidator(item); setShowConfirmModal(true); }} className='delete-btn'>
                                    {t('development:evaluation:deleteValidator')}
                                </button>
                            }
                            {item.status == FeedbackStatus.Completed &&
                                <button onClick={() => handleShowEvaluation(item)} className='delete-btn'>{t('development:evaluation:showEvaluation')}</button>
                            }
                        </li>
                    ))}
                </ul>
            </div>

            <ConfirmModal
                title={t('development:evaluation:modalDeleteValidatorTitle')}
                description={t('development:evaluation:modalDeleteValidatorDescription')}
                showModal={showConfirmModal}
                isSubmitting={isSubmitting}
                onConfirm={handleDelete}
                onClose={() => setShowConfirmModal(false)} />

            {selectedFeedback && (
                <EvaluationPopup
                    feedbacks={selectedFeedback.attributeFeedbacks}
                    showModal={showPopup}
                    handleClose={handleClosePopup}
                />
            )}
        </>
    )
}

export default FeedbackStatusOverview