import { createRef, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import dayjs from 'dayjs'
import { t } from 'i18next';

type DateControlProps = {
    value: string;
    onChange: (event: any) => void;
    required?: boolean | false;
    controlType: DateControlType;
    minValue?: string;
};

export enum DateControlType {
    Full = 1,
    MonthAndYear = 2
}

function DateControl({ value, required, controlType, minValue, onChange }: DateControlProps) {

    type DateControlState = {
        day: string | undefined;
        month: string | undefined;
        year: string | undefined;
    }

    const dayInputRef = createRef<HTMLInputElement>();
    const monthInputRef = createRef<HTMLInputElement>();
    const yearInputRef = createRef<HTMLInputElement>();

    const [date, setDate] = useState<DateControlState | undefined>();

    useEffect(() => {
        if (value !== undefined) {
            const date = dayjs(value);
            setDate({
                day: date.format('DD'),
                month: date.format('MM'),
                year: date.format('YYYY')
            });
        }
    }, [value]);

    useEffect(() => {
        if (minValue !== undefined) {
            validate(dayjs(value).toDate());
        }
    }, [minValue]);

    const handleInputChange = (event: any) => {
        const value = event.target.value;
        const name = event.target.name;

        var newDateState = {
            ...date,
            [name]: value
        };

        var newDate = dayjs(newDateState.year + '-' + newDateState.month?.padStart(2, '0') + '-' + (controlType == DateControlType.MonthAndYear ? '01' : newDateState.day?.padStart(2, '0')) + 'T00:00:00Z', 'YYYY-MM-DDTHH:mm:ssZ');

        try {

            const isValidDate = validate(newDate.toDate());

            if (isValidDate) {
                onChange({ target: { value: newDate.toISOString() } });
            }

            setDate(newDateState);
        }
        catch (exception_var) {
            console.error(exception_var);
            event.target.isValid = false;
        }
    }

    const validate = (editedDate: Date) => {
        var isvalid = true;

        if (editedDate !== undefined
            && dayjs(editedDate).isValid()
            && Number(editedDate.getFullYear()) > 1900
            && (minValue === undefined || editedDate >= new Date(minValue))) {

            dayInputRef.current?.setCustomValidity('')
            monthInputRef.current?.setCustomValidity('')
            yearInputRef.current?.setCustomValidity('')
            isvalid = true;

        } else {
            dayInputRef.current?.setCustomValidity(t('general:invalidDate'))
            monthInputRef.current?.setCustomValidity(t('general:invalidDate'))
            yearInputRef.current?.setCustomValidity(t('general:invalidDate'))
            isvalid = false;
        }

        return isvalid;
    }

    return (
        controlType === DateControlType.Full ? (

            <Form.Group as={Row} sm={12} >
                <Col sm={3} className='mb-3 mb-md-0'>
                    <Form.Control ref={dayInputRef} type="text" placeholder={t('general:dayPlaceholder')} required={required} value={date?.day ?? ''} name="day" onChange={(e) => handleInputChange(e)} />
                </Col>
                <Col sm={3} className='mb-3 mb-md-0'>
                    <Form.Control ref={monthInputRef} type="text" placeholder={t('general:monthPlaceholder')} required={required} value={date?.month ?? ''} name="month" onChange={(e) => handleInputChange(e)} />
                </Col>
                <Col sm={6} className=''>
                    <Form.Control ref={yearInputRef} type="text" placeholder={t('general:yearPlaceholder')} required={required} value={date?.year ?? ''} name="year" onChange={(e) => handleInputChange(e)} />
                </Col>
            </Form.Group>

        ) : (
            <Form.Group as={Row} sm={12} >
                <Col sm={3} className='mb-2 mb-md-0'>
                    <Form.Control ref={monthInputRef} type="text" placeholder={t('general:monthPlaceholder')} required={required} value={date?.month ?? ''} name="month" onChange={(e) => handleInputChange(e)} />
                </Col>
                <Col sm={6} className='mb-2 mb-md-0'>
                    <Form.Control ref={yearInputRef} type="text" placeholder={t('general:yearPlaceholder')} required={required} value={date?.year ?? ''} name="year" onChange={(e) => handleInputChange(e)} />
                </Col>
            </Form.Group>
        )
    );
}

export default DateControl;