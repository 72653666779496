import Button from '@/components/button/Button';
import { ProfileContext } from '@/context/ProfileContext';
import { DATE_FORMAT_FULL, PROFILE_PERSONAL_DATA_FIELDS } from '@/general/constants';
import { useGenderTranslation } from '@/general/i18n/translation-helpers';
import { useMissingProfileCardRequirements } from '@/hooks/useMissingProfileCardRequirements';
import { Profile } from '@/profile/model/profile';
import dayjs from 'dayjs';
import { motion } from 'framer-motion';
import { t } from 'i18next';
import { createRef, useContext, useState } from 'react';
import { Card, CloseButton, Col, Form, Modal, ProgressBar, Row } from 'react-bootstrap';
import { Gender } from '../model/gender';
import './ProfileCard.scss';

function ProfileCandidatePersonalData() {

    const profileContext = useContext(ProfileContext);
    const [showEditModal, setShowEditModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const formRef = createRef<HTMLFormElement>();
    const { translateGender } = useGenderTranslation();

    const personalDataFields = PROFILE_PERSONAL_DATA_FIELDS;
    const { percentComplete, totalRequirementsCount, completedRequirementsCount } = useMissingProfileCardRequirements(personalDataFields);

    const genderOptions = Object.keys(Gender)
        .filter(key => !isNaN(Number(key)))
        .map(key => ({ value: Number(key), label: translateGender(Number(key)) }));

    const openModal = () => {
        setValidated(false);
        setShowEditModal(true);
    }

    const closeModal = () => {
        setShowEditModal(false);
        profileContext.updateChanges(profileContext.profile);
    };

    const saveChanges = () => {
        setValidated(true);
        if (formRef.current?.checkValidity()) {
            profileContext.saveProfile(profileContext.tempChanges)
                .then(() => { closeModal(); })
                .catch(() => { });
        }
    };

    const updateTempChanges = (propertyName, newValue) => {

        const updatedProfile: Profile = {
            ...profileContext.tempChanges,
            [propertyName]: newValue
        };

        profileContext.updateChanges(updatedProfile);
    };

    return (
        <>
            <Card className="profile-card mb-4">
                <Card.Header>
                    <Card.Title>{t('profile:personalData:title')}</Card.Title>
                    <motion.button whileTap={{ scale: 0.85 }} onClick={() => openModal()}><i className="fa-solid fa-pen" /></motion.button>
                </Card.Header>
                <Card.Body>
                    <Card.Text as="div">
                        {(completedRequirementsCount != totalRequirementsCount) &&
                            <div className='profile-requirements'>
                                <ProgressBar now={percentComplete} label={`${percentComplete}%`} />
                                <p className='count'>{completedRequirementsCount}/{totalRequirementsCount} {t('profile:personalData:requirementsCompleted')}</p>
                            </div>
                        }
                        <ul>
                            <li>{profileContext.profile?.givenName} {profileContext.profile?.surname}</li>
                            <li>{profileContext.profile?.email}</li>
                            <li>{(profileContext.profile?.dateOfBirth === undefined || !dayjs(profileContext.profile.dateOfBirth).isValid()) ? '' : dayjs(profileContext.profile.dateOfBirth).format(DATE_FORMAT_FULL)}</li>
                            <li>{profileContext.profile?.phone}</li>
                            <li>{profileContext.profile?.street} {profileContext.profile?.houseNumber}</li>
                            <li>{profileContext.profile?.postalCode} {profileContext.profile?.city}</li>
                        </ul>
                    </Card.Text>
                </Card.Body>
            </Card>

            <Modal className='wihv-modal wihv-modal--profile' size='lg' show={showEditModal} backdrop="static" centered>
                <Modal.Header>
                    <Modal.Title>{t('profile:personalData:title')}</Modal.Title>
                    <CloseButton variant='white' onClick={() => closeModal()} />
                </Modal.Header>
                <Modal.Body className='p-3 p-md-5'>
                    <Form ref={formRef} validated={validated} noValidate>
                        <Form.Group as={Row}>
                            <Col>
                                <Form.Label><span>{t('profile:personalData:labelGivenName')}</span><span className='required'>*</span></Form.Label>
                                <Form.Control type="text" placeholder="" required autoComplete='given-name' value={profileContext.tempChanges?.givenName ?? ''} onChange={e => updateTempChanges('givenName', e.target.value)} />
                            </Col>
                            <Col>
                                <Form.Label><span>{t('profile:personalData:labelSurname')}</span><span className='required'>*</span></Form.Label>
                                <Form.Control type="text" placeholder="" required autoComplete='family-name' value={profileContext.tempChanges?.surname ?? ''} onChange={e => updateTempChanges('surname', e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-4">
                            <Col>
                                <Form.Label><span>{t('profile:personalData:labelEmail')}</span><span className='required'>*</span></Form.Label>
                                <Form.Control type="email" placeholder="" autoComplete='email' value={profileContext.tempChanges?.email ?? ''} required onChange={e => updateTempChanges('email', e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-4">
                            <Col>
                                <Form.Label>{t('profile:personalData:labelDateOfBirth')}</Form.Label>
                                <Form.Control type="date" name="dateOfBirth" placeholder="" autoComplete="bday" max={new Date().toISOString().split('T')[0]}
                                    defaultValue={profileContext.tempChanges?.dateOfBirth ? dayjs(profileContext.tempChanges?.dateOfBirth).format('YYYY-MM-DD') : ''}
                                    onBlur={(e) => { if (e.target.value) updateTempChanges('dateOfBirth', e.target.value) }} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-4">
                            <Col>
                                <Form.Label>{t('profile:personalData:labelGender')}</Form.Label>
                                <Form.Select defaultValue={''} autoComplete='sex' onChange={(e) => updateTempChanges('gender', e.target.value)}>
                                    <option value="" disabled>{t('profile:personalData:placeholderGender')}</option>
                                    {genderOptions.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-4">
                            <Col>
                                <Form.Label>{t('profile:personalData:labelPhone')}</Form.Label>
                                <Form.Control type="phone" placeholder="" autoComplete='tel' value={profileContext.tempChanges?.phone ?? ''} onChange={e => updateTempChanges('phone', e.target.value)} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mt-4">
                            <Col>
                                <Form.Label>{t('profile:personalData:labelStreet')}</Form.Label>
                                <Form.Control type="text" placeholder="" autoComplete='street-address' value={profileContext.tempChanges?.street ?? ''} onChange={e => updateTempChanges('street', e.target.value)} />
                            </Col>
                            <Col>
                                <Form.Label>{t('profile:personalData:labelHouseNumber')}</Form.Label>
                                <Form.Control type="text" placeholder="" autoComplete='address-line2' value={profileContext.tempChanges?.houseNumber ?? ''} onChange={e => updateTempChanges('houseNumber', e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-4">
                            <Col>
                                <Form.Label>{t('profile:personalData:labelPostalCode')}</Form.Label>
                                <Form.Control type="text" placeholder="" autoComplete='postal-code' value={profileContext.tempChanges?.postalCode ?? ''} onChange={e => updateTempChanges('postalCode', e.target.value)} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mt-4">
                            <Col>
                                <Form.Label>{t('profile:personalData:labelCity')}</Form.Label>
                                <Form.Control type="text" placeholder="" autoComplete='address-level2' value={profileContext.tempChanges?.city ?? ''} onChange={e => updateTempChanges('city', e.target.value)} />
                            </Col>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button displayType='secondary' text={t('general:cancel')} onClick={() => closeModal()} />
                    <Button displayType='primary' text={t('general:save')} loading={profileContext.requestInProgress} onClick={() => saveChanges()} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ProfileCandidatePersonalData;